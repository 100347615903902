import { VerifiedMeasurement } from "types/OrderTypes";
import { OrderType, ArchivalDateType } from "types/OrderTypes";
import { ARCHIVAL_TYPE, ORDER_ENUM } from "enums";
import { CompletedMeasurements } from "features/orders";
import { hasOrderStatus } from "utils";
import { meetingAddress } from "../utils";

interface ArchivalDatesDetailsProps {
  orderDetails: OrderType;
  userRole: string | undefined;
}

const ArchivalDatesDetails = ({
  orderDetails,
  userRole,
}: ArchivalDatesDetailsProps) => {
  const isMeasurementsAttempt = orderDetails?.archivalDates?.filter(
    (item) => item.type === ARCHIVAL_TYPE.MEASUREMENTS_ATTEPMT
  );
  const isMeasurementsTaken = orderDetails?.archivalDates?.filter(
    (item) => item.type === ARCHIVAL_TYPE.MEASUREMENTS_TAKEN
  );
  const isVerificationVisit = orderDetails?.archivalDates?.filter(
    (item) => item.type === ARCHIVAL_TYPE.VERIFICATION_VISIT
  );
  const isInstallationAttempt = orderDetails?.archivalDates?.filter(
    (item) => item.type === ARCHIVAL_TYPE.INSTALLATION_ATTEMPT
  );
  const isAdditionalVisit = orderDetails?.archivalDates?.filter(
    (item) => item.type === ARCHIVAL_TYPE.ADDITIONAL_VISIT
  );
  const orderStatus = orderDetails?.status;
  const {
    MEASUREMENTS_TO_BE_RELEASED,
    FIXED_MEASUREMENTS,
    MEASUREMENTS_DONE,
    FIXED_VERIFICATION,
    VERIFICATION_TO_BE_RELEASED,
  } = ORDER_ENUM;

  const isShowInstallationAttempt =
    orderStatus !== MEASUREMENTS_TO_BE_RELEASED &&
    orderStatus !== MEASUREMENTS_DONE &&
    orderStatus !== FIXED_MEASUREMENTS;

  const showVerification = hasOrderStatus(orderDetails.status, [
    FIXED_VERIFICATION,
    MEASUREMENTS_DONE,
    VERIFICATION_TO_BE_RELEASED,
  ]);
  const isMeasurementDate = orderStatus !== MEASUREMENTS_TO_BE_RELEASED;
  const isCheckListAvailable = !!(
    orderDetails.additionalVisitCheckList &&
    orderDetails.additionalVisitCheckList.length > 0
  );
  const showAssemblyDate =
    orderDetails.date && isMeasurementDate && !isCheckListAvailable;

  const isAdditionalInstallation =
    orderDetails &&
    orderDetails.additionalVisitCheckList &&
    orderDetails.additionalVisitCheckList.length > 0;

  return (
    <div className="archival-dates">
      {isMeasurementsAttempt && isMeasurementsAttempt.length > 0 && (
        <>
          <div className="modal__information-header">
            Nieudane próby wykonania pomiaru
          </div>
          {isMeasurementsAttempt.map(
            ({ date, start, end, employees, comment }: ArchivalDateType) => {
              return (
                <div className="failed-meeting-attempt">
                  <div className="failed-meeting-attempt__content">
                    <div className="failed-meeting-attempt__section">
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Termin:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {date}, godz: {start} - {end}
                        </div>
                      </div>
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Pracownik:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {employees?.map(({ firstName, lastName }) => {
                            return (
                              <>
                                {firstName} {lastName}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="failed-meeting-attempt__separator" />
                    <div className="failed-meeting-attempt__section">
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Komentarz:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {comment}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </>
      )}
      {showAssemblyDate && (
        <>
          <div className="modal__information-header">
            {meetingAddress(orderDetails?.status)}
          </div>
          <div className="modal__information-descript">
            {orderDetails.date ? (
              <>
                {orderDetails.date}, godz. {orderDetails.start}-
                {orderDetails.end}
              </>
            ) : (
              "-"
            )}
          </div>
        </>
      )}
      {isMeasurementsTaken && isMeasurementsTaken.length > 0 && (
        <>
          <div className="modal__information-header">
            Data wykonania pomiaru
          </div>
          {isMeasurementsTaken.map(
            ({ date, start, end, employees }: ArchivalDateType) => {
              return (
                <div className="failed-meeting-attempt">
                  <div className="failed-meeting-attempt__content">
                    <div className="failed-meeting-attempt__section">
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Termin:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {date}, godz: {start} - {end}
                        </div>
                      </div>
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Pracownik:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {employees?.map(({ firstName, lastName }) => {
                            return (
                              <>
                                {firstName} {lastName}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </>
      )}
      <CompletedMeasurements userRole={userRole} orderDetails={orderDetails} />
      {isVerificationVisit && isVerificationVisit.length > 0 && (
        <>
          <div className="modal__information-header">Weryfikacja</div>
          {isVerificationVisit.map(
            ({ date, start, end, employees }: ArchivalDateType) => {
              return (
                <div className="failed-meeting-attempt">
                  <div className="failed-meeting-attempt__content">
                    <div className="failed-meeting-attempt__section">
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Termin:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {date}, godz: {start} - {end}
                        </div>
                      </div>
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Pracownik:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {employees?.map(({ firstName, lastName }) => {
                            return (
                              <>
                                {firstName} {lastName}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </>
      )}
      {orderDetails?.verificationParams &&
        orderDetails?.verificationParams?.length > 0 &&
        showVerification && (
          <>
            <div className="modal__information-header">Do weryfikacji</div>
            <div className="verified-list">
              {orderDetails.verificationParams.map(
                (item: VerifiedMeasurement) => {
                  return (
                    <>
                      <div className="verified-list__measurement">
                        {item.label}
                      </div>
                      {item.toFix &&
                        item.toFix.length > 0 &&
                        item.toFix.map((fix) => (
                          <div className="verified-list__toFix">
                            {typeof fix === "string" ? fix : fix.label}
                          </div>
                        ))}
                    </>
                  );
                }
              )}
            </div>
          </>
        )}
      {isShowInstallationAttempt &&
        isInstallationAttempt &&
        isInstallationAttempt.length > 0 && (
          <>
            <div className="modal__information-header">
              Nieudane próby wykonania montażu
            </div>
            {isInstallationAttempt.map(
              ({ date, start, end, employees, comment }: ArchivalDateType) => {
                return (
                  <div className="failed-meeting-attempt">
                    <div className="failed-meeting-attempt__content">
                      <div className="failed-meeting-attempt__section">
                        <div className="failed-meeting-attempt__line">
                          <div className="failed-meeting-attempt__label">
                            Termin:
                          </div>
                          <div className="failed-meeting-attempt__value">
                            {date}, godz: {start} - {end}
                          </div>
                        </div>
                        <div className="failed-meeting-attempt__line">
                          <div className="failed-meeting-attempt__label">
                            Pracownik:
                          </div>
                          <div className="failed-meeting-attempt__value">
                            {employees?.map(
                              ({ firstName, lastName }, index) => {
                                const isMoreThanOneAndNotLast =
                                  index + 1 < employees.length;
                                return (
                                  <>
                                    {firstName} {lastName}
                                    {isMoreThanOneAndNotLast ? ", " : ""}
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                      {comment && (
                        <>
                          <div className="failed-meeting-attempt__separator" />
                          <div className="failed-meeting-attempt__section">
                            <div className="failed-meeting-attempt__line">
                              <div className="failed-meeting-attempt__label">
                                Komentarz:
                              </div>
                              <div className="failed-meeting-attempt__value">
                                {comment}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                );
              }
            )}
          </>
        )}

      {!isAdditionalInstallation &&
        isAdditionalVisit &&
        isAdditionalVisit.length > 0 && (
          <>
            <div className="modal__information-header">
              Montaże uzupełniające
            </div>
            {isAdditionalVisit.map(
              ({ date, start, end, employees }: ArchivalDateType) => {
                return (
                  <div className="failed-meeting-attempt">
                    <div className="failed-meeting-attempt__content">
                      <div className="failed-meeting-attempt__section">
                        <div className="failed-meeting-attempt__line">
                          <div className="failed-meeting-attempt__label">
                            Termin:
                          </div>
                          <div className="failed-meeting-attempt__value">
                            {date}, godz: {start} - {end}
                          </div>
                        </div>
                        <div className="failed-meeting-attempt__line">
                          <div className="failed-meeting-attempt__label">
                            Pracownik:
                          </div>
                          <div className="failed-meeting-attempt__value">
                            {employees?.map(({ firstName, lastName }) => {
                              return (
                                <>
                                  {firstName} {lastName}
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </>
        )}
    </div>
  );
};

export default ArchivalDatesDetails;
