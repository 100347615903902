/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FieldProps } from "formik";
import "./textarea.scss";

interface InputProps {
  label: string;
  name: string;
  type: string;

  placeholder?: string;
  className?: string;
  disabled?: boolean;
  secondValue?: string;
  multi?: boolean;
  treshold?: number;
  removable?: boolean;
  maxLength?: number;
  id?: string;
  inputMode?: string;
  pattern?: string;
  variant?: string;
  optional?: boolean;
  //formik
  field: any;
  errors: any;
  form: any;
  touched?: any;
  onChange?: (value: any) => void;
  handleOnDebounce?: (value: any) => void;
  handleOnChange?: any;
  isOnBlurAction: boolean;
  editUser: (data: any) => void;
  onFocus: any;
  onBlur?: any;
}

function Textarea({
  label,
  className = "",
  pattern,
  name,
  type,
  form,
  errors = {},
  field,
  onChange,
  treshold = 500,
  id,
  maxLength,
  optional = false,
  onBlur,
  disabled,
  handleOnDebounce,
}: InputProps & FieldProps) {
  const [value, setValue] = useState<string>(field?.value);
  const [debouncedValues, setDebouncedValues] = useState<string>(field?.value);
  const isArrayField = field.name.includes(".");
  const [textPart, setTextPart] = useState("");
  const dotIndex = field.name.indexOf(".");
  const elementIndex = field.name.substring(dotIndex + 1);
  const fieldName = field.name.substring(0, dotIndex);

  function removeNonNumericChars(inputString: any) {
    return inputString.replace(/\D/g, "");
  }

  useEffect(() => {
    if (isArrayField) {
      const parts = field.name.split(".");
      if (parts.length > 2) {
        const dynamicTextPart = parts.slice(2).join(".");
        setTextPart(dynamicTextPart);
      }
    }
  }, [form.values[fieldName], field.value]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValues(value);
      handleOnDebounce && handleOnDebounce(value);
    }, treshold);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [value]);

  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  useEffect(() => {
    if (debouncedValues) {
      form.setFieldValue(field.name, value);
    }
  }, [debouncedValues]);

  function showError() {
    if (isArrayField) {
      if (dotIndex !== -1) {
        if (elementIndex.includes(".")) {
          const afterRemoveString = removeNonNumericChars(elementIndex);

          const errorTextPart =
            errors[fieldName]?.[afterRemoveString]?.[textPart] || "";
          return (
            <div className="input__error">
              {form?.touched[fieldName] &&
                form.touched[fieldName][afterRemoveString] && (
                  <span>{errorTextPart}</span>
                )}
            </div>
          );
        } else {
          const errorTextPart =
            errors[fieldName]?.[elementIndex]?.[textPart] || "";

          return (
            <div className="input__error">
              {form?.touched[fieldName] &&
                form.touched[fieldName][elementIndex] && (
                  <span>{errorTextPart}</span>
                )}
            </div>
          );
        }
      } else {
        return null;
      }
    } else if (errors && form.touched) {
      const errorTextPart = errors[field.name]?.[textPart] || "";
      return (
        <div className="input__error">
          {form.touched[field.name] && (
            <span>{errorTextPart || errors[field.name]}</span>
          )}
        </div>
      );
    }
  }

  return (
    <div
      id={id}
      className={`textarea__wrapper ${className} ${
        form.touched[field.name] && errors[field.name]
          ? "textarea__wrapper--error"
          : ""
      }`}
    >
      <label className="textarea__label" htmlFor={field.name}>
        {label}{" "}
        {optional ? <span className="input__optional">(opcjonalne)</span> : ""}
      </label>
      <textarea
        disabled={disabled}
        maxLength={maxLength}
        {...field}
        name={field.name}
        className={`textarea ${
          form.touched[field.name] && errors[field.name]
            ? "textarea--border-error"
            : ""
        }`}
        value={field.value}
        onChange={(e) => {
          form.setFieldValue(field.name, e.target.value);
          setValue(e.target.value);
          onChange && onChange(e.target.value);
        }}
        onBlur={(e) => onBlur && onBlur(e)}
        pattern={pattern}
        id={name}
        type={type}
      />
      {showError()}

      {/* {errors && form.touched && (
        <div className="textarea__error">
          {form.touched[field.name] && <span>{errors[field.name]}</span>}
        </div>
      )} */}
    </div>
  );
}

export default Textarea;
