/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import "./working-time.scss";
import { SVG, ContextMenu, Select, Input } from "components";
import { SVG_TYPE } from "enums";
import { Field, Formik } from "formik";
import { WorkingTimeForm, DeleteWorkingTime } from "features/orders";
import {
  usePostActivityTimeStart,
  usePutActivityTimeStop,
  useUpdateActivityTime,
} from "hooks/activity-time";
import { useSearchParams } from "react-router-dom";
import { useGetMe } from "hooks/auth";
import { convertTime, isMobile } from "utils";
import { debounce } from "lodash";

interface WorkingTimeType {
  isActive?: boolean;
  timeManagement?: any;
  label?: string;
  activity: any;
}

const WorkingTime = ({
  isActive = false,
  label,
  timeManagement,
  activity,
}: WorkingTimeType) => {
  const isManyActivity = activity.numberOfActivities === "many";
  const isActiveTime = activity && activity.countingTime;

  const activeTime =
    activity && activity.numberOfActivities === "one"
      ? activity.timeManagement &&
        activity.timeManagement.length > 0 &&
        activity.timeManagement.find((item: any) => item.countingTime)
      : activity.activityLabel &&
        activity.activityLabel.length > 0 &&
        activity.activityLabel.find((item: any) => item.allTime);
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");
  const [isWorkingStart, setIsWorkingStart] = useState(isActiveTime);
  const [selectObject, setSelectObject] = useState<any>();
  const [modalManage, setModalManage] = useState({
    type: "",
    data: {},
  });
  const inputRef = useRef(null);
  const manyOptions = () => {
    let options: any = [];
    activity?.activityLabel?.forEach((item: any) => {
      options.push({ value: item.activityName, label: item.activityName });
    });
    return options;
  };

  useEffect(() => {
    !selectObject &&
      setSelectObject(
        activeTime?.activityName
          ? manyOptions().find(
              (item: any) => item.value === activeTime?.activityName
            )
          : manyOptions()[0]
      );
  }, [manyOptions]);

  const formatDateTime = (dateTime: string) => {
    const [date, time] = dateTime.split(" ");
    const [hours, minutes] = time.split(":");
    return `${date}, ${hours}:${minutes}`;
  };

  useEffect(() => {
    setIsWorkingStart(isActiveTime);
  }, [isActiveTime]);

  const { mutate: startActivityTime } = usePostActivityTimeStart({
    onSuccess: (data) => {
      setIsWorkingStart(!isWorkingStart);
    },
  });

  const { mutate: updateActivityTime } = useUpdateActivityTime();

  const { data: authorizedUser } = useGetMe();
  const { mutate: stopActivityTime } = usePutActivityTimeStop();

  const isAddWorkingTime = modalManage.type === "addWorkingTime";
  const isDeleteWorkingTime = modalManage.type === "deleteWorkingTime";
  const clearModalManage = () => {
    setModalManage({ type: "", data: modalManage.data });
  };
  const setDeleteWorkingTime = (data: any) => {
    setModalManage({ type: "deleteWorkingTime", data });
  };
  const setAddWorkingTime = (data: any) => {
    setModalManage({ type: "addWorkingTime", data });
  };
  const setEditWorkingTime = (data: any) => {
    setModalManage({ type: "addWorkingTime", data });
  };

  const workingTimeMenu = (label: any, uuid: string, item: any) => {
    return [
      {
        name: "Edytuj",
        disabled: item.countingTime,
        action: () => {
          setEditWorkingTime({
            name: isActive ? selectObject.label : label,
            type: "edit",
            uuid,
            item,
          });
        },
      },
      {
        name: "Usuń",
        disabled: item.countingTime,
        action: () => {
          setDeleteWorkingTime({ name: label, type: "delete", uuid, item });
        },
      },
    ];
  };

  const handleTrigerTime = () => {
    if (!isWorkingStart) {
      startActivityTime({
        orderUuid,
        activityUuid: timeManagement?.uuid,
        activityName: selectObject ? selectObject.value : "",
      });
    } else {
      stopActivityTime({
        timeManagementUuid: isManyActivity
          ? activeTime.timeManagement.find((item: any) => item.countingTime)
              .uuid
          : activeTime.uuid,
      });
    }
  };
  const handleWriteComment = debounce((e: any, timeManagementUuid: string) => {
    updateActivityTime({ comment: e.target.value, timeManagementUuid });
  }, 1000);

  const handleCommentKeyDown = (e: any) => {
    if (e.key === "Enter") {
      (e.target as HTMLInputElement).blur();
    }
  };

  const activityTimeManagement = isManyActivity
    ? activeTime &&
      activeTime.timeManagement &&
      activeTime.timeManagement.length > 0 &&
      activeTime.timeManagement
    : activity &&
      activity.timeManagement &&
      activity.timeManagement.length > 0 &&
      activity.timeManagement;

  return (
    <>
      <WorkingTimeForm
        isOpen={isAddWorkingTime}
        data={modalManage.data}
        onClose={() => clearModalManage()}
      />
      <DeleteWorkingTime
        data={modalManage.data}
        isOpen={isDeleteWorkingTime}
        onClose={() => clearModalManage()}
      />
      <div className="working-time">
        <div
          className={`working-time-item ${
            activity.countingTime ? "working-time-item--active" : ""
          }`}
        >
          <div className="working-time-item__content">
            <div className="working-time-item__left">
              <Formik
                initialValues={{
                  type: isManyActivity ? manyOptions()[0].value : "",
                  comment: "",
                }}
                enableReinitialize
                onSubmit={async (values: any) => console.log(values)}
              >
                {({ handleSubmit }) => {
                  return (
                    <form className="working-time-form" onSubmit={handleSubmit}>
                      {isManyActivity && !activeTime?.allTime ? (
                        <>
                          <Field
                            name="type"
                            required
                            onChange={(value: any) => setSelectObject(value)}
                            component={Select}
                            options={manyOptions()}
                          />
                        </>
                      ) : (
                        <div className="working-time-item__name">
                          {isManyActivity ? selectObject?.value : label}
                        </div>
                      )}
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="working-time-item__right">
              <div className="working-time-item__start">Razem:</div>
              <div className="working-time-item__timing">
                {isWorkingStart ? (
                  <div className="working-time-item__timing-with-clock">
                    <SVG type={SVG_TYPE.CLOCK} />
                    {isManyActivity
                      ? convertTime(activeTime?.allTime)
                      : activity.allTime && convertTime(activity.allTime)}
                  </div>
                ) : activity.allTime ? (
                  convertTime(activity.allTime)
                ) : activeTime && activeTime.allTime ? (
                  convertTime(activeTime.allTime)
                ) : (
                  "0m"
                )}
              </div>
              <div className="working-time-item__buttons">
                <div
                  className="working-time-item__button"
                  onClick={() => handleTrigerTime()}
                >
                  {isWorkingStart ? (
                    <SVG type={SVG_TYPE.STOP} />
                  ) : (
                    <SVG type={SVG_TYPE.PLAY} />
                  )}
                </div>
                <div
                  className="working-time-item__button"
                  onClick={() =>
                    setAddWorkingTime({
                      name: isActive
                        ? selectObject
                          ? selectObject.label
                          : ""
                        : label,
                      uuid: "",
                      type: "add",
                      orderUuid,
                      activityUuid: activity.uuid,
                      selectObject,
                      activityName: selectObject?.value,
                    })
                  }
                >
                  <SVG type={SVG_TYPE.PLUS} />
                </div>
              </div>
            </div>
          </div>

          {activity.userSymbols && (
            <div
              className="working-time-bar"
              style={{
                backgroundColor: authorizedUser
                  ? authorizedUser.userColor ?? ""
                  : "",
              }}
            >
              <div className="working-time-bar__user">
                {activity.userSymbols}
              </div>
              <div className="working-time-bar__time">
                {isManyActivity
                  ? convertTime(activeTime.allTime)
                  : activity.allTime && convertTime(activity.allTime)}
              </div>
              {activity?.countingTime && (
                <SVG
                  className="working-time-bar__clock"
                  type={SVG_TYPE.CLOCK}
                />
              )}
            </div>
          )}

          {activityTimeManagement &&
            activityTimeManagement.map((item: any, index: number) => {
              const firstElement = index === 0;
              return (
                <div
                  className={`single-timing ${
                    firstElement ? "single-timing--first" : ""
                  } ${item.comment ? "single-timing--comment" : ""}`}
                >
                  <div className="working-time-bar__user single-timing--user">
                    {item.userSymbols}
                  </div>
                  <div className="working-time-bar__time">
                    {convertTime(item.countedTime)}
                  </div>
                  <div className="single-timing__form">
                    <div className="single-timing-form">
                      <Formik
                        initialValues={{
                          type: "",
                          comment: item.comment,
                        }}
                        enableReinitialize
                        onSubmit={async (values: any) => console.log(values)}
                      >
                        {({ handleSubmit }) => {
                          return (
                            <form
                              className={`working-time-form`}
                              onSubmit={handleSubmit}
                            >
                              <Field
                                name="comment"
                                placeholder={
                                  isMobile()
                                    ? "Dodaj komentarz"
                                    : "Kliknij, aby dodać komentarz"
                                }
                                ref={inputRef}
                                component={Input}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  handleWriteComment(e, item.uuid);
                                }}
                                onKeyDown={(e: any) => {
                                  handleCommentKeyDown(e);
                                }}
                              />
                            </form>
                          );
                        }}
                      </Formik>
                    </div>
                    <div className="single-timing__date">
                      {formatDateTime(item.dateStart)}
                    </div>
                  </div>
                  <div className="working-time-item__menu single-timing--menu">
                    <ContextMenu
                      className="dtb__user-submenu"
                      items={workingTimeMenu(label, item.uuid, item)}
                    >
                      <SVG type={SVG_TYPE.ELLIPSIS} />
                    </ContextMenu>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default WorkingTime;
