import { OrderType } from "types/OrderTypes";
import { Button, SVG } from "components";
import { SVG_TYPE, USER_ROLE, ROUTE_ENUM, ARCHIVAL_TYPE } from "enums";
import { hasAccess } from "utils";
import { useNavigate } from "react-router";
import { ArchivalDateType } from "types/OrderTypes";

interface AdditionalAppointmentProps {
  orderDetails: OrderType;
  userRole: string | undefined;
}

const AdditionalAppointment = ({
  orderDetails,
  userRole,
}: AdditionalAppointmentProps) => {
  const navigate = useNavigate();
  const setAppointment = (type: "edit" | "new") => {
    const isEdit = type === "edit";
    navigate(
      `${ROUTE_ENUM.ARRANGING_MEASUREMENT}?uuid=${
        orderDetails.uuid
      }&tab=fixedOrders&type=additionalVisit${isEdit ? "&isEdit=true" : ""}`
    );
  };

  const handleGoToActiveOrder = () => {
    navigate(`${ROUTE_ENUM.ACTIVE_ORDER}?orderUuid=${orderDetails.uuid}`);
  };

  const {
    additionalVisitCheckList,
    additionalVisitRequired,
    measurementsArrangedInstallations,
    date,
    start,
    end,
    employee,
    comments,
    archivalDates,
  } = orderDetails;

  const isCheckListAvailable = !!(
    additionalVisitCheckList && additionalVisitCheckList.length > 0
  );
  const isScheduleManager = hasAccess(userRole, [USER_ROLE.scheduleManager]);

  const showSetAppointment = isScheduleManager && additionalVisitRequired;

  const isFinalDate = date && employee;

  const installations =
    measurementsArrangedInstallations &&
    measurementsArrangedInstallations.length > 0 &&
    measurementsArrangedInstallations.filter(
      (measurement) => measurement.additionalVisit
    );
  const isAdditionalVisit = archivalDates?.filter(
    (item) => item.type === ARCHIVAL_TYPE.ADDITIONAL_VISIT
  );
  return (
    <>
      {showSetAppointment ? (
        <>
          <div className="order-preview__error-dimension error-dimension">
            <p>Konieczność umówienia spotkania uzupełniającego.</p>
            <SVG type={SVG_TYPE.EXCLAMATION} />
          </div>
          {(!isCheckListAvailable || !isFinalDate) && (
            <Button
              className="order-preview__set-appointment"
              size="medium"
              label="Umów montaż uzupełniający"
              onClick={() => setAppointment("new")}
            />
          )}
        </>
      ) : null}
      {isCheckListAvailable && (
        <>
          <div className="modal__information-header">Montaż uzupełniający</div>
          <div className="additional-installation-preview">
            {isFinalDate && (
              <div className="additional-installation-preview__segment additional-installation-preview__segment--top additional-installation-preview--separator">
                <div className="additional-installation-preview__left">
                  <div className="line">
                    <div className="header">Termin:</div>
                    <div className="paragraph">
                      {date && `${date}, godz. ${start}-${end}`}
                    </div>
                  </div>
                  <div className="line">
                    <div className="header">Pracownik</div>
                    <div className="paragraph">
                      {employee && `${employee.firstName} ${employee.lastName}`}
                    </div>
                  </div>
                </div>
                {isScheduleManager ? (
                  <div
                    className="additional-installation-preview__right"
                    onClick={() => setAppointment("edit")}
                  >
                    <SVG type={SVG_TYPE.EDIT} />
                    Edytuj
                  </div>
                ) : (
                  <div
                    className="additional-installation-preview__right"
                    onClick={() => handleGoToActiveOrder()}
                  >
                    Wykonaj
                  </div>
                )}
              </div>
            )}

            {comments && (
              <div className="additional-installation-preview__segment additional-installation-preview--separator">
                <div className="line">
                  <div className="header">Komentarz:</div>
                  <div className="paragraph">{comments}</div>
                </div>
              </div>
            )}
            <div className="additional-installation-preview__segment">
              <div className="line">
                <div className="header">Lista Czynności:</div>
              </div>
              <div className="additional-installation-preview__checkList">
                {additionalVisitCheckList.map(({ text, status }, index) => (
                  <div
                    className="additional-installation-preview__checkList-item"
                    key={index}
                  >
                    {text}
                    {status ? (
                      <div className="additional-installation-preview__check">
                        <SVG type={SVG_TYPE.BLUE_CHECK_CIRCLE} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="additional-comments__list">
            {installations &&
              installations.map((installation) => {
                return (
                  <div
                    className="additional-comments__item"
                    key={installation.groupUuid}
                  >
                    <p className="header">
                      Komentarz montera - montaż uzupełniający
                    </p>
                    <p className="paragraph">
                      {installation.additionalVisitComments}
                    </p>
                  </div>
                );
              })}
          </div>
        </>
      )}
      {isAdditionalVisit && isAdditionalVisit.length > 0 && (
        <>
          <div className="modal__information-header">
            Próby wkonania montażu uzupełniającego
          </div>
          {isAdditionalVisit.map(
            ({ date, start, end, employees }: ArchivalDateType) => {
              return (
                <div className="failed-meeting-attempt">
                  <div className="failed-meeting-attempt__content">
                    <div className="failed-meeting-attempt__section">
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Termin:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {date}, godz: {start} - {end}
                        </div>
                      </div>
                      <div className="failed-meeting-attempt__line">
                        <div className="failed-meeting-attempt__label">
                          Pracownik:
                        </div>
                        <div className="failed-meeting-attempt__value">
                          {employees?.map(({ firstName, lastName }) => {
                            return (
                              <>
                                {firstName} {lastName}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </>
      )}
    </>
  );
};

export default AdditionalAppointment;
