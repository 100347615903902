/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { OrderType } from "types/OrderTypes";
import { Button, Input, SVG } from "components";
import { FieldProps } from "formik";
import "./additional-installation.scss";
import { Field, FieldArray } from "formik";
import { SVG_TYPE } from "enums";

interface AdditionalInstallationProps {
  orderDetails: OrderType;
  errors: any;
  values: any;
  field: any;
  form: any;
  setCheckList: any;
  checkList: any;
}

const AdditionalInstallation = ({
  orderDetails,
  errors,
  values,
  form,
  setCheckList,
  checkList,
}: AdditionalInstallationProps & FieldProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { measurementsArrangedInstallations } = orderDetails;
  const installations =
    measurementsArrangedInstallations &&
    measurementsArrangedInstallations.length > 0 &&
    measurementsArrangedInstallations.filter(
      (measurement) => measurement.additionalVisit
    );

  const handleAddItem = (arrayHelpers: any) => {
    arrayHelpers.push("");
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const additionalChecked =
    orderDetails &&
    orderDetails.additionalVisitCheckList &&
    orderDetails.additionalVisitCheckList.filter((item) => item.status === 1);

  useEffect(() => {
    const areArraysEqual = (arr1: any, arr2: any) => {
      if (arr1.length !== arr2.length) return false;
      return arr1.every((item: any, index: any) => item === arr2[index]);
    };
    if (!areArraysEqual(values.checkList, checkList)) {
      setCheckList(values.checkList);
    }
  }, [values.checkList]);

  return (
    <div className="additional">
      <div className="additional-comments__list">
        {installations &&
          installations.map((installation) => {
            return (
              <div
                className="additional-comments__item"
                key={installation.groupUuid}
              >
                <p className="header">
                  Komentarz montera - montaż uzupełniający
                </p>
                <p className="paragraph">
                  {installation.additionalVisitComments}
                </p>
              </div>
            );
          })}
      </div>
      <div className="additional-actions">
        <div className="header">Lista czynności</div>
        <div className="check-list">
          {additionalChecked && additionalChecked.length > 0 && (
            <>
              <div className="mal__form-activities">
                {additionalChecked.map((item, index) => {
                  return (
                    <div
                      key={`checkListItem_${index + 1}`}
                      className="edit-checklist__form-item edit-checklist__form-item--disabled"
                    >
                      <div className="input__wrapper">
                        <div className="input__content">
                          <input
                            className="input"
                            type="text"
                            value={item.text}
                            disabled
                          />
                        </div>
                        <div className="input__error"></div>
                      </div>
                      <SVG type={SVG_TYPE.BLUE_CHECK_CIRCLE} />
                    </div>
                  );
                })}
              </div>
            </>
          )}
          <FieldArray
            name="checkList"
            render={(arrayHelpers) => (
              <div className="mal__form-activities">
                {values?.checkList.map((item: any, index: any) => {
                  return (
                    <div
                      key={`checkListItem_${index + 1}`}
                      className="edit-checklist__form-item"
                    >
                      <Field
                        errors={errors}
                        name={`checkList.${index}`}
                        component={Input}
                        binNumber={0}
                        bin
                        secondary
                        inputRef={inputRef}
                      />
                    </div>
                  );
                })}
                <div>
                  <Button
                    stroke
                    className="add-point"
                    label="Dodaj kolejny punkt"
                    size="small"
                    onClick={() => handleAddItem(arrayHelpers)}
                  />
                </div>
              </div>
            )}
          />
        </div>

        {form.touched && errors["checkList"] && (
          <div className="input__error">
            {Array.isArray(errors["checkList"]) &&
            errors["checkList"].length > 0
              ? errors["checkList"].find((error) => error) || null
              : typeof errors["checkList"] === "string"
              ? errors["checkList"]
              : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdditionalInstallation;
