import moment from "moment";
import { isMobile } from "./isMobile";

const createDate = (dateStr: string, timeStr: string): Date => {
    const [day, month, year] = dateStr
      .split("/")
      .map((num) => parseInt(num, 10));
    const [hour, minute = 0] = timeStr
      .split(":")
      .map((num) => parseInt(num, 10));
    return new Date(year, month - 1, day, hour, minute);
  };


  export const convertToEvents = (orders: any, color: string = ''): any => {
    const sortedEvents = [...orders].sort((a, b) => {
        const dateA = moment(`${a.date} ${a.start}`, "DD/MM/YYYY HH:mm").toDate();
        const dateB = moment(`${b.date} ${b.start}`, "DD/MM/YYYY HH:mm").toDate();
        return dateA.getTime() - dateB.getTime();
    });

    const events: any = [];

    sortedEvents.forEach((order, index) => {
      if (order.initialDate) {
          if (isMobile()) {
              const startDate = moment(order.date, "DD/MM/YYYY");
              const endDate = moment(order.dateEnd, "DD/MM/YYYY");

              if (endDate.diff(startDate, 'days') > 0) {
                  for (let i = 0; i <= endDate.diff(startDate, 'days'); i++) {
                      const newDate = startDate.clone().add(i, 'days').format("DD/MM/YYYY");
                      events.push({
                          title: order.title || order.name || '',
                          start: createDate(newDate, order.start),
                          end: createDate(newDate, order.end),
                          original: color ? { ...order, userColor: color } : { ...order },
                          index: `${index}-${i}`
                      });
                  }
              } else {
                  events.push({
                      title: order.title || order.name || '',
                      start: createDate(order.date, order.start),
                      end: createDate(order.dateEnd, order.end),
                      original: color ? { ...order, userColor: color } : { ...order },
                      index: `${index}`
                  });
              }
          } else {
              events.push({
                  title: order.title || order.name || '',
                  start: createDate(order.date, order.start),
                  end: createDate(order.dateEnd, order.end),
                  original: color ? { ...order, userColor: color } : { ...order },
                  index: `${index}`
              });
          }
      } else {
          events.push({
              title: order.title || order.name || '',
              start: createDate(order.date, order.start),
              end: createDate(order.date, order.end),
              original: color ? { ...order, userColor: color } : { ...order },
              index: `${index}`
          });
      }
  });

  return events;

};