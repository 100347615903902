
export enum FILE_ENUM {
  ORDER_INVOICE = "orderInvoice",
  MEASUREMENTS_PHOTO = "measurementPhoto",
  INSTALLATION_PHOTO = "installationPhoto",
  ADDITIONAL_VISIT_CURRENT_PHOTO = 'additionalVisitCurrentPhoto',
  ADDITIONAL_VISIT_INSTALLATION_PHOTO = 'additionalVisitInstallationPhoto',
  GENERATED_PDF = "generatedPdf",
  CUSTOM_MEASUREMENT_FILE = "customMeasurementFile",
}

