import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

interface AdditionalInstallationVerificationParams {
  uuid: string;
  checkListUuids: string[];
}

const useAdditionalInstallationVerification = (
  options?: UseMutationOptions<any, Error, AdditionalInstallationVerificationParams>
) => {
  return useMutation({
    ...options,
    mutationKey: ['additionalInstallationVerification'],
    mutationFn: async ({ uuid, checkListUuids }) => {
      return axiosInstance.put(`/orders/additional-visit/${uuid}`, {
        checkListUuids,
      }).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie montażu uzupełniającego...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'additional-installation-verification',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Montaż uzupełniający zakończony sukcesem',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'additional-installation-verification',
      });
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas przetwarzania montażu uzupełniającego',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'additional-installation-verification',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useAdditionalInstallationVerification;
