import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

interface Employee {
  firstName: string;
  lastName: string;
  uuid: string;
}

interface TeamMember {
  start: string;
  end: string;
  employees: Employee[];
}

interface DateEntry {
  date: string;
  team: TeamMember[];
}

interface Installation {
  measurementName: string;
  dates: DateEntry[];
}

interface UpdateInstallationDetailsType {
  uuid: string | null;
  installations: Installation[];
}

const useAddInstallationOrder = (
  options?: UseMutationOptions<any, Error, UpdateInstallationDetailsType>
) => {
  return useMutation({
    ...options,
    mutationKey: ['addInstallationOrder'],
    mutationFn: async ({ uuid, installations }) => {
      return axiosInstance.post(`/orders/determining-installation/${uuid}`, {
        installations,
      }).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie aktualizacji montażu...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'update-installation',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Umówiono montaż',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'update-installation',
      });
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas umawiania montażu',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'update-installation',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useAddInstallationOrder;
