import { useState } from "react";
import { measurementAllInstallationType } from "types/OrderTypes";
import "./interim-date.scss";
import { Input, SVG, SingleDateRangePicker, Button } from "components";
import { SVG_TYPE, VALIDATION_MESSAGES } from "enums";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useUpdateInterimDate } from "hooks/orders";

interface InterimDateProps {
  measurement: measurementAllInstallationType;
  isCustom: boolean;
}

const InterimDate = ({ measurement, isCustom }: InterimDateProps) => {
  const { measurementUuid, initialDate } = measurement;
  const [isOpen, setIsOpen] = useState(false);

  const udpateDate = useUpdateInterimDate({
    onSuccess: (data) => {
      setIsOpen(false);
    },
  });

  const handleUpdateDate = (values: any) => {
    udpateDate.mutate({
      measurementUuid: measurementUuid,
      type: !!isCustom ? "custom" : "regular",
      dateStart: values.date.dateStart,
      dateEnd: values.date.dateEnd,
      numberOfInstallationCrews: values.numberOfInstallationCrews,
    });
  };
  const currentDate = new Date();
  const today = currentDate.setDate(currentDate.getDate());

  const validationSchema = Yup.object().shape({
    date: Yup.mixed()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test(
        "is-valid-date",
        VALIDATION_MESSAGES.REQUIRED,
        (value) =>
          typeof value === "string" ||
          (typeof value === "object" &&
            value !== null &&
            "dateStart" in value &&
            "dateEnd" in value &&
            typeof (value as any).dateStart === "string" &&
            typeof (value as any).dateEnd === "string")
      ),
    numberOfInstallationCrews: Yup.number().required(
      VALIDATION_MESSAGES.REQUIRED
    ),
  });
  const handleTriggerForm = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="installation-planning__right interim-date">
        {(!!isCustom && initialDate) || (!isCustom && initialDate) ? (
          <div className="interim-date__action" onClick={handleTriggerForm}>
            <SVG type={SVG_TYPE.EDIT} />
            Edytuj
          </div>
        ) : (
          <div className="interim-date__action" onClick={handleTriggerForm}>
            <SVG type={SVG_TYPE.PLUS_CIRCLE} />
            Dodaj informacje
          </div>
        )}
      </div>
      {isOpen && (
        <div className="interim-date__form">
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              date: {
                dateStart: measurement?.initialDate?.start,
                dateEnd: measurement?.initialDate?.end,
              },
              numberOfInstallationCrews:
                measurement?.initialDate?.numberOfInstallationCrews,
            }}
            enableReinitialize
            onSubmit={async (values) => handleUpdateDate(values)}
            validateOnChange={true}
            validateOnBlur={false}
          >
            {({ handleSubmit, errors }) => {
              return (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(e);
                  }}
                >
                  <Field
                    errors={errors}
                    label="Wstępny termin"
                    name="date"
                    component={SingleDateRangePicker}
                    minDate={today}
                  />
                  <Field
                    errors={errors}
                    label="Ilość ekip monterskich"
                    maxLength={2}
                    name="numberOfInstallationCrews"
                    icon={SVG_TYPE.USERS_BOLD}
                    component={Input}
                  />
                  <div className="interim-date__form__buttons">
                    <Button
                      stroke
                      size="small"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                      label="Anuluj"
                    />
                    <Button
                      size="small"
                      onClick={() => handleSubmit()}
                      label="Potwierdź"
                    />
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
};

export default InterimDate;
