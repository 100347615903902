/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from "react";
import "./photos.scss";
import { PhotosSection, PhotosModal } from "features/Photos";
import { FILE_ENUM } from "enums";
import { PHOTOS_ENUM_TYPE } from "types/PhotosTypes";
import { OrderType } from "types/OrderTypes";
import { useUploadFile } from "hooks/utils";
import { useSearchParams } from "react-router-dom";
import { useDeleteFile } from "hooks/utils";
import { StaticAlert } from "components";

interface PhotosType {
  label: string;
  type: PHOTOS_ENUM_TYPE | PHOTOS_ENUM_TYPE[];
  orderDetails: OrderType;
  adding?: boolean;
  isErrorPhotos?: { isError: boolean; type: string | string[] };
  setIsErrorPhotos?: ({
    isError,
    type,
  }: {
    isError: boolean;
    type: string | string[];
  }) => void;
  userRole: string | undefined;
}

const Photos = ({
  label,
  type,
  orderDetails,
  isErrorPhotos,
  setIsErrorPhotos,
  userRole,
  adding = false,
}: PhotosType) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [activePhoto, setActivePhoto] = useState<number | null>(null);
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");
  const groupUuid = searchParams.get("groupUuid");
  const photos = orderDetails.files.filter((item: any) => {
    if (Array.isArray(type)) {
      return type.includes(item.type);
    } else {
      return item.type === type;
    }
  });

  const [queue, setQueue] = useState<{ id: string; file: File }[]>([]);
  const [failedUploads, setFailedUploads] = useState<
    { id: string; file: File }[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const [uploadingFiles, setUploadingFiles] = useState<
    { id: string; file: File }[]
  >([]);
  const uploadFile = useUploadFile({
    onSuccess: () => {
      setIsErrorPhotos && setIsErrorPhotos({ isError: false, type: "" });
    },
  });
  const { mutate: deleteFile } = useDeleteFile();
  const isFailedError = failedUploads && failedUploads.length > 0;

  const handleAddPhotos = () => {
    fileInputRef.current?.click();
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    const supportedFiles = files.filter(
      (file) =>
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png"
    );

    if (supportedFiles.length > 0) {
      const fileIds = supportedFiles.map((file) => ({
        id: `${file.name}-${file.size}-${file.lastModified}`,
        file,
      }));

      setUploadingFiles((prev) => [...prev, ...fileIds]);
      setQueue((prev) => [...prev, ...fileIds]);
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleRemoveFailedUpload = (id: string | number) => {
    setFailedUploads((prev) => prev.filter((file) => file.id !== id));
  };

  const handleDeleteFile = (uuid: string) => {
    if (Array.isArray(type)) {
      const selectedType = type.includes(
        FILE_ENUM.ADDITIONAL_VISIT_CURRENT_PHOTO
      )
        ? FILE_ENUM.ADDITIONAL_VISIT_CURRENT_PHOTO
        : type[0];
      if (selectedType) {
        deleteFile({ uuid, type: selectedType });
      }
    } else if (type) {
      deleteFile({ uuid, type });
    }
  };

  const retryFailedUpload = (id: string) => {
    const failedFile = failedUploads.find((file) => file.id === id);
    if (failedFile) {
      setQueue((prev) => [...prev, failedFile]);
      setFailedUploads((prev) => prev.filter((file) => file.id !== id));
    }
  };

  useEffect(() => {
    if (queue.length > 0) {
      processQueue();
    }
  }, [queue]);

  const processQueue = async () => {
    if (queue.length > 0 && type) {
      const { id, file } = queue[0];

      const resolvedType = Array.isArray(type)
        ? type.includes(FILE_ENUM.ADDITIONAL_VISIT_CURRENT_PHOTO)
          ? FILE_ENUM.ADDITIONAL_VISIT_CURRENT_PHOTO
          : type[0]
        : type;

      if (!resolvedType) {
        console.error("Resolved type is null or undefined.");
        return;
      }
      try {
        await new Promise<void>((resolve, reject) => {
          uploadFile.mutate(
            {
              parentUuid:
                type === FILE_ENUM.INSTALLATION_PHOTO
                  ? groupUuid || ""
                  : orderUuid || "",
              type: resolvedType,
              files: [file],
            },
            {
              onSuccess: () => {
                setUploadedFiles((prev) => [
                  ...prev,
                  URL.createObjectURL(file),
                ]);
                resolve();
              },
              onError: (error) => {
                setFailedUploads((prev) => [...prev, { id, file }]);
                reject(error);
              },
            }
          );
        });
      } finally {
        setUploadingFiles((prev) =>
          prev.filter((uploadingFile) => uploadingFile.id !== id)
        );
        setQueue((prev) => prev.slice(1));
      }
    }
  };
  return (
    <div
      className={`photos-active-order ${
        photos.length > 0 ? "photos-active-order--with-photo" : ""
      }`}
    >
      <PhotosModal
        adding={adding}
        userRole={userRole}
        setActivePhoto={setActivePhoto}
        activePhoto={activePhoto}
        orderStatus={orderDetails?.status}
        type={type}
        photos={photos}
        failedUploads={failedUploads}
        handleAddPhotos={handleAddPhotos}
        handleDeleteFile={handleDeleteFile}
        isOpen={isModalOpen}
        handleRemoveFailedUpload={handleRemoveFailedUpload}
        onClose={() => setIsModalOpen(false)}
        retryFailedUpload={retryFailedUpload}
        label={label}
      />
      <PhotosSection
        adding={adding}
        userRole={userRole}
        setActivePhoto={setActivePhoto}
        activePhoto={activePhoto}
        photosType={type}
        orderStatus={orderDetails?.status}
        handleAddPhotos={handleAddPhotos}
        handleDeleteFile={handleDeleteFile}
        handleRemoveFailedUpload={handleRemoveFailedUpload}
        retryFailedUpload={retryFailedUpload}
        fileInputRef={fileInputRef}
        handleFileSelect={handleFileSelect}
        failedUploads={failedUploads}
        uploadedFiles={uploadedFiles}
        uploadingFiles={uploadingFiles}
        photos={photos}
        label={label}
        setIsModalOpen={setIsModalOpen}
      />
      {isFailedError ||
        (isErrorPhotos && isErrorPhotos.type === type) ||
        (isErrorPhotos &&
          type != null &&
          (Array.isArray(type)
            ? type.some((t) =>
                [
                  FILE_ENUM.ADDITIONAL_VISIT_CURRENT_PHOTO,
                  FILE_ENUM.ADDITIONAL_VISIT_INSTALLATION_PHOTO,
                ].includes(t as FILE_ENUM)
              )
            : [
                FILE_ENUM.ADDITIONAL_VISIT_CURRENT_PHOTO,
                FILE_ENUM.ADDITIONAL_VISIT_INSTALLATION_PHOTO,
              ].includes(type as FILE_ENUM)) && (
            <StaticAlert
              show={isFailedError || (isErrorPhotos && isErrorPhotos.isError)}
              label={
                isErrorPhotos && type === FILE_ENUM.MEASUREMENTS_PHOTO
                  ? "Musisz dodać conajmniej jedno zdjęcie z pomiaru"
                  : isErrorPhotos && type === FILE_ENUM.INSTALLATION_PHOTO
                  ? "Musisz dodać conajmniej jedno zdjęcie z montażu"
                  : isErrorPhotos &&
                    type != null &&
                    (Array.isArray(type)
                      ? type.some((t) =>
                          [
                            FILE_ENUM.ADDITIONAL_VISIT_CURRENT_PHOTO,
                            FILE_ENUM.ADDITIONAL_VISIT_INSTALLATION_PHOTO,
                          ].includes(t as FILE_ENUM)
                        )
                      : [
                          FILE_ENUM.ADDITIONAL_VISIT_CURRENT_PHOTO,
                          FILE_ENUM.ADDITIONAL_VISIT_INSTALLATION_PHOTO,
                        ].includes(type as FILE_ENUM))
                  ? "Musisz dodać conajmniej jedno zdjęcie z montażu uzupełniajcego"
                  : isFailedError
                  ? "Nie udało się dodać zdjęć, na których widać ikonkę z wykrzyknikiem. Spróbuj dodać je ponownie."
                  : ""
              }
            />
          ))}
    </div>
  );
};

export default Photos;
