import { useState, useEffect } from "react";
import { Button, SVG, Textarea } from "components";
import { SVG_TYPE, VALIDATION_MESSAGES } from "enums";
import { Field } from "formik";

interface AdditionalWarningType {
  errors: any;
  isErrorDimension: boolean;
  values: any;
  setFieldValue: any;
  setFieldError: any;
}

const AdditionalWarning = ({
  isErrorDimension,
  errors,
  values,
  setFieldValue,
  setFieldError,
}: AdditionalWarningType) => {
  const [isAdditionalComment, setIsAdditionalComment] = useState(false);

  useEffect(() => {
    if (values.warningComment) {
      setIsAdditionalComment(true);
    } else if (values.warningComment === "") {
      setIsAdditionalComment(false);
    }
  }, [values.warningComment]);

  const handleWriteComment = (comment: string) => {
    setFieldValue("warningComment", comment);
    if (values.warningComment === "") {
      setFieldError("warningComment", VALIDATION_MESSAGES.REQUIRED);
    } else {
      setFieldError("warningComment", "");
    }
  };

  return (
    <>
      {isErrorDimension && (
        <>
          <div className="error-dimension">
            <p>
              Sprawdź, czy szerokość/wysokość jest prawidłowa. Jeśli tak, to
              napisz wyjaśnienie w komentarzu.
            </p>
            {values.warningComment === "" && (
              <>
                {!isAdditionalComment && (
                  <Button
                    className="error-dimensions__button"
                    size="small"
                    secondary
                    stroke
                    label="Dodaj komentarz"
                    onClick={() => setIsAdditionalComment(true)}
                  />
                )}
              </>
            )}
            <SVG type={SVG_TYPE.EXCLAMATION} />
          </div>
          {isAdditionalComment && (
            <>
              <Field
                className="am-margin-top"
                errors={errors}
                label="Komentarz wyjaśniający"
                name="warningComment"
                component={Textarea}
                maxLength={240}
                onChange={(e: string) => handleWriteComment(e)}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default AdditionalWarning;
