import { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./date-range-picker.scss";
import { pl } from "date-fns/locale";
import { registerLocale } from "react-datepicker";
import { Button, SVG } from "components";
import moment from "moment";
import { SVG_TYPE } from "enums";
import { isMobile } from "utils";

interface DataRangePickerType {
  onChangeDate: any;
  label: string;
}

const DateRangePicker = ({ onChangeDate, label }: DataRangePickerType) => {
  const now = new Date();
  const [startDate, setStartDate] = useState<any>(
    new Date(now.getFullYear(), now.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState<any>(
    new Date(now.getFullYear(), now.getMonth() + 1, 0)
  );
  const [tempStartDate, setTempStartDate] = useState<any>(
    new Date(now.getFullYear(), now.getMonth(), 1)
  );
  const [tempEndDate, setTempEndDate] = useState<any>(
    new Date(now.getFullYear(), now.getMonth() + 1, 0)
  );
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [rangeLabel, setRangeLabel] = useState<string>(label);

  const calendarRef = useRef<HTMLDivElement>(null);

  const removeClasses = () => {
    const dateElements = document.querySelectorAll(".react-datepicker__day");
    dateElements.forEach((element) => {
      const textContent = element.textContent || "";
      if (!textContent.trim()) {
        element.classList.remove("react-datepicker__day--in-range");
        element.classList.remove("react-datepicker__day--outside-month");
        element.classList.remove("react-datepicker__day--range-end");
        element.classList.remove("react-datepicker__day--range-start");
        element.classList.remove("react-datepicker__day--selected");
      }
    });
  };

  useEffect(() => {
    removeClasses();
  }, [startDate, tempEndDate, tempStartDate, endDate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node)
      ) {
        setIsCalendarOpen(false);
      }
    };

    if (isCalendarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isCalendarOpen]);

  const handleSave = () => {
    if (rangeLabel === "Ostatnie 7 dni") {
      onChangeDate("last7days");
    } else if (rangeLabel === "Ostatni miesiąc") {
      onChangeDate("lastMonth");
    } else if (rangeLabel === "Obecny miesiąc") {
      onChangeDate("currentMonth");
    } else if (rangeLabel === "Obecny tydzień") {
      onChangeDate("currentWeek");
    } else {
      onChangeDate(
        `${moment(tempStartDate).format("DD/MM/YYYY")}-${moment(
          tempEndDate
        ).format("DD/MM/YYYY")}`
      );
    }
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    setIsCalendarOpen(false);
  };

  const handleCancel = () => {
    setTempStartDate(startDate);
    setTempEndDate(endDate);
    setIsCalendarOpen(false);
    setRangeLabel("Ostatni miesiąc");
  };

  const updateRangeLabel = (start: Date, end: Date) => {
    moment.updateLocale("pl", {
      week: {
        dow: 1,
      },
    });

    const now = moment().startOf("day");
    const startMoment = moment(start).startOf("day");
    const endMoment = moment(end).endOf("day");

    const last7DaysStart = now.clone().subtract(6, "days");
    const last7DaysEnd = now.clone().subtract(1, "day");
    const lastMonthStart = now.clone().subtract(1, "month").startOf("month");
    const lastMonthEnd = now.clone().subtract(1, "month").endOf("month");
    const currentMonthStart = now.clone().startOf("month");
    const currentMonthEnd = now.clone().endOf("month");
    const currentWeekStart = now.clone().startOf("week");
    const currentWeekEnd = now.clone().endOf("week");
    if (
      startMoment.isSame(currentWeekStart, "day") &&
      endMoment.isSame(currentWeekEnd, "day")
    ) {
      setRangeLabel("Obecny tydzień");
    } else if (
      startMoment.isSame(last7DaysStart, "day") &&
      endMoment.isSame(last7DaysEnd, "day")
    ) {
      setRangeLabel("Ostatnie 7 dni");
    } else if (
      startMoment.isSame(lastMonthStart, "day") &&
      endMoment.isSame(lastMonthEnd, "day")
    ) {
      setRangeLabel("Ostatni miesiąc");
    } else if (
      startMoment.isSame(currentMonthStart, "day") &&
      endMoment.isSame(currentMonthEnd, "day")
    ) {
      setRangeLabel("Obecny miesiąc");
    } else {
      const startDate = startMoment.format("DD/MM/YYYY");
      const endDate = endMoment.isValid()
        ? endMoment.format("DD/MM/YYYY")
        : null;

      if (endDate && startDate !== endDate) {
        setRangeLabel(`${startDate} - ${endDate}`);
      } else {
        setRangeLabel(startDate);
      }
    }
  };

  const changeMonth = (
    changeMonth: any,
    setYear: any,
    date: any,
    label: string
  ) => {
    const monthString = moment(date).format("MM");
    const yearString = moment(date).format("YYYY");
    const monthNumber = parseInt(monthString, 10);
    setRangeLabel(label);
    setYear(yearString);
    if (label === "Ostatni miesiąc") {
      changeMonth(monthNumber);
    } else {
      changeMonth(monthNumber - 1);
    }
  };

  const handleLast7Days = (setMonth: any, setYear: any) => {
    const now = new Date();
    const endOfLast7Days = new Date(now);
    const startOfLast7Days = new Date(now);
    startOfLast7Days.setDate(now.getDate() - 6);

    setTempStartDate(startOfLast7Days);
    setTempEndDate(endOfLast7Days);
    changeMonth(setMonth, setYear, startOfLast7Days, "Ostatnie 7 dni");
  };

  const handleLastMonth = (setMonth: any, setYear: any) => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth(), 0);
    setTempStartDate(startOfMonth);
    setTempEndDate(endOfMonth);
    changeMonth(setMonth, setYear, startOfMonth, "Ostatni miesiąc");
  };

  const handleCurrentMonth = (setMonth: any, setYear: any) => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    setTempStartDate(startOfMonth);
    setTempEndDate(endOfMonth);
    changeMonth(setMonth, setYear, startOfMonth, "Obecny miesiąc");
  };

  const handleCurrentWeek = (setMonth: any, setYear: any) => {
    const now = new Date();
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - now.getDay() + 1);
    const endOfWeek = new Date(now);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    setTempStartDate(startOfWeek);
    setTempEndDate(endOfWeek);
    changeMonth(setMonth, setYear, startOfWeek, "Obecny tydzień");
  };

  const CustomCalendarContainer = ({ children }: any) => (
    <div className="date-range-picker__wrapper" ref={calendarRef}>
      <div className="date-range-picker">{children}</div>
      <div className="date-range-picker__sidebar">
        <div></div>
        <div className="date-range-picker__actions">
          <Button stroke size="medium" label="Anuluj" onClick={handleCancel} />
          <Button size="medium" label="Potwierdź " onClick={handleSave} />
        </div>
      </div>
    </div>
  );

  const customPlLocale = {
    ...pl,
    localize: {
      ...pl.localize,
      day: (n: any, options: any) => {
        const dayNames = ["Nd", "Pn", "Wt", "Śr", "Cz", "Pt", "Sb"];
        return dayNames[n];
      },
    },
  };
  registerLocale("custom-pl", customPlLocale);

  const [isSelectingRange, setIsSelectingRange] = useState<boolean>(false);

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    if (start && end) {
      updateRangeLabel(start, end);
    }

    if (!isSelectingRange) {
      setTempStartDate(start);
      setTempEndDate(null);
      setIsSelectingRange(true);
    } else if (start && end) {
      setTempStartDate(start);
      setTempEndDate(end);
      setIsSelectingRange(false);
    } else if (start && start < tempStartDate) {
      setTempStartDate(start);
      setTempEndDate(null);
    } else if (start && start > tempEndDate) {
      setTempStartDate(start);
      setTempEndDate(null);
    } else {
      setTempStartDate(start);
      setTempEndDate(end);
    }
  };

  const maxDate =
    isSelectingRange && tempStartDate
      ? moment(tempStartDate).add(30, "days").toDate()
      : null;

  return (
    <div className="date-range-picker-container">
      <div
        className={`date-range-picker__input-container ${
          isCalendarOpen && "date-range-picker__input-container--open"
        }`}
        onClick={() => setIsCalendarOpen(true)}
        ref={calendarRef}
      >
        <div
          className={`date-range-picker__input-content ${
            isCalendarOpen ? "date-range-picker__input-content--open" : ""
          }`}
        >
          <div className="date-range-picker__input-icon">
            <SVG type={SVG_TYPE.CALENDAR} />
          </div>
          <div className="date-range-picker__input">{rangeLabel}</div>
          <div className="date-range-picker__input-icon date-range-picker__chevrone">
            <SVG type={SVG_TYPE.CHEVRON_DOWN} />
          </div>
        </div>
      </div>
      <DatePicker
        open={isCalendarOpen}
        calendarClassName="date-range-picker"
        popperClassName="date-range-picker__popper"
        selected={tempStartDate}
        onChange={onChange}
        startDate={tempStartDate}
        endDate={tempEndDate}
        locale="custom-pl"
        selectsRange
        monthsShown={isMobile() ? 1 : 2}
        showPreviousMonths
        onCalendarOpen={() => setIsCalendarOpen(true)}
        onInputClick={() => setIsCalendarOpen(true)}
        onCalendarClose={() => setIsCalendarOpen(false)}
        shouldCloseOnSelect={false}
        calendarContainer={CustomCalendarContainer}
        onMonthChange={() => setTimeout(() => removeClasses(), 10)}
        minDate={null}
        maxDate={maxDate} //
        renderCustomHeader={({
          monthDate,
          decreaseMonth,
          increaseMonth,
          changeMonth,
          changeYear,
        }) => {
          return (
            <div className="date-range-picker__header">
              <button
                aria-label="Previous Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--previous"
                }
                onClick={decreaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                  }
                >
                  {"<"}
                </span>
              </button>
              <span className="react-datepicker__current-month">
                {monthDate.toLocaleString("pl", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
              <button
                aria-label="Next Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--next"
                }
                onClick={increaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                  }
                >
                  {">"}
                </span>
              </button>
              <div className="date-range-picker__auto-range">
                <div
                  className="date-range-picker__label"
                  onClick={() => handleLast7Days(changeMonth, changeYear)}
                >
                  Ostatnie 7 dni
                </div>
                <div
                  className="date-range-picker__label"
                  onClick={() => handleLastMonth(changeMonth, changeYear)}
                >
                  Ostatni miesiąc
                </div>
                <div
                  className="date-range-picker__label"
                  onClick={() => handleCurrentWeek(changeMonth, changeYear)}
                >
                  Obecny tydzień
                </div>
                <div
                  className="date-range-picker__label"
                  onClick={() => handleCurrentMonth(changeMonth, changeYear)}
                >
                  Obecny miesiąc
                </div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default DateRangePicker;
