import { Modal, Button } from "components";

interface ArchiveOrderProps {
  isOpen: boolean;
  onClose: () => void;
  data: any;
}

const ArchiveOrder = ({ isOpen, onClose, data }: ArchiveOrderProps) => {
  const handleSubmit = () => {
    console.log("submit");
  };

  return (
    <>
      <Modal
        className="am-close-modal"
        isOpened={isOpen}
        onClose={() => onClose()}
      >
        <>
          <div className="modal-heading">Archiwizacja zlecenia</div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz zarchiwizować <span>{data.name}</span>
          </div>
          <div className="modal-buttons">
            <Button stroke onClick={() => onClose()} label="Anuluj" />
            <Button onClick={() => handleSubmit()} label="Potwierdź" />
          </div>
        </>
      </Modal>
    </>
  );
};

export default ArchiveOrder;
