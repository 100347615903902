import "./file-item.scss";
import { SVG, Modal, Button } from "components";
import { useState } from "react";
import { SVG_TYPE } from "enums";
import { MEASUREMENTS_TYPE_TRANSLATIONS } from "enums";
import { MEASUREMENTS_TYPE } from "types/OrderTypes";
import { useDeleteFile } from "hooks/utils";
import { FileInvoice } from "types/OrderTypes";

interface ConfirmationProps {
  isConfirmated?: boolean;
  text?: string;
}

interface FileItemType {
  isDeleteAvailable?: boolean;
  file: FileInvoice;
  label?: string;
  confirmation?: ConfirmationProps;
}

const FileItem = ({
  label,
  file,
  isDeleteAvailable = false,
  confirmation = { isConfirmated: false, text: "" },
}: FileItemType) => {
  const { name, url, uuid, type } = file || {
    name: "",
    url: "",
    uuid: "",
    type: "",
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate: deleteFile } = useDeleteFile({
    onSuccess: () => {
      console.log("File successfully deleted!");
    },
  });

  const handleDownloadFileItem = () => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleDeleteFileConfirmation = () => {
    deleteFile({ uuid, type });
  };

  return (
    <>
      <Modal
        className="delete-activity delete-invoice"
        isOpened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <>
          <div className="modal-heading">Usuwanie pliku</div>
          <div className="modal-description">{confirmation.text}</div>
          <div className="modal-buttons">
            <Button
              stroke
              onClick={() => setIsModalOpen(false)}
              label="Anuluj"
            />
            <Button
              onClick={() => handleDeleteFileConfirmation()}
              label="Potwierdź"
            />
          </div>
        </>
      </Modal>
      <div className="file-item">
        <p className="file-item__label">
          {name in MEASUREMENTS_TYPE_TRANSLATIONS
            ? MEASUREMENTS_TYPE_TRANSLATIONS[name as MEASUREMENTS_TYPE]
            : name}
        </p>
        <div className="file-item__icon__wrapper">
          {url && (
            <div
              className="file-item__icon__content"
              onClick={() => handleDownloadFileItem()}
            >
              <SVG className="file-item__icon" type={SVG_TYPE.DOWNLOAD_FILE} />
            </div>
          )}
          {isDeleteAvailable && url && (
            <>
              <div
                className="file-item__icon__content"
                onClick={() =>
                  confirmation.isConfirmated
                    ? setIsModalOpen(true)
                    : handleDeleteFileConfirmation()
                }
              >
                <SVG className="file-item__icon" type={SVG_TYPE.BIN} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FileItem;
