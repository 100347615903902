import { Button, Modal } from "components";
import { useFinishOrder } from "hooks/orders"; // Importujemy hook

interface ChangeOrderStatusProps {
  data: any;
  isOpen: boolean;
  onClose: () => void;
  status: string;
}

const ChangeOrderStatus = ({
  isOpen,
  onClose,
  data,
  status,
}: ChangeOrderStatusProps) => {
  const finishOrderMutation = useFinishOrder();

  const handleConfirm = () => {
    if (data.uuid) {
      finishOrderMutation.mutate(
        { uuid: data.uuid },
        {
          onSuccess: () => {
            onClose();
          },
        }
      );
    }
  };

  return (
    <>
      <Modal
        className="order-status__modal"
        isOpened={isOpen}
        onClose={() => onClose()}
      >
        <>
          <div className="modal-heading">Zmiana statusu zlecenia</div>
          <div className="modal-description">
            Czy jesteś pewien, że chcesz zmienić status zlecenia
            <span>{data.name}</span> na <span>{status}</span>
          </div>
          <div className="modal-buttons">
            <Button stroke onClick={() => onClose()} label="Anuluj" />
            <Button onClick={handleConfirm} label="Potwierdź" />{" "}
          </div>
        </>
      </Modal>
    </>
  );
};

export default ChangeOrderStatus;
