import { Field, Formik } from "formik";
import { VALIDATION_MESSAGES } from "enums";
import { Button, Modal, CalendarInput, Textarea } from "components";
import { useUpdateActivityTime, useAddActivityTime } from "hooks/activity-time";
import moment from "moment";
import * as Yup from "yup";

interface WorkingTimeFormType {
  isOpen: boolean;
  onClose: () => void;
  data: any;
}

const WorkingTimeForm = ({ isOpen, onClose, data }: WorkingTimeFormType) => {
  const { mutate: updateActivityTime } = useUpdateActivityTime({
    onSuccess: () => {
      onClose();
    },
  });
  const { mutate: startActivityTime } = useAddActivityTime({
    onSuccess: () => {
      onClose();
    },
  });

  const initialValues = {
    dateStart: data?.item?.dateStart || "",
    dateEnd: data?.item?.dateEnd || "",
    comment: data?.item?.comment || "",
    activityName: data?.activityName,
  };
  const validationSchema = Yup.object().shape({
    dateStart: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    dateEnd: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  });
  const isEdit = data.type === "edit";

  const handleOnSubmit = (values: any) => {
    const addSecondsIfMissing = (dateTime: string): string => {
      const [date, time] = dateTime.split(" ");
      if (time && time.split(":").length === 2) {
        return `${date} ${time}:00`;
      }
      return dateTime;
    };
    const updatedValues = {
      ...values,
      dateStart: addSecondsIfMissing(values.dateStart),
      dateEnd: addSecondsIfMissing(values.dateEnd),
    };

    if (isEdit) {
      updateActivityTime({
        ...updatedValues,
        timeManagementUuid: data.uuid,
        activityName: data?.selectedObject?.value,
      });
    } else {
      startActivityTime({
        ...updatedValues,
        orderUuid: data.orderUuid,
        activityUuid: data.activityUuid,
      });
    }
  };

  return (
    <>
      <Modal side="right" isOpened={isOpen} onClose={() => onClose()}>
        <>
          <div className="modal-heading">
            {isEdit ? "Edycja" : "Dodawanie"} czasu pracy
          </div>
          <div className="modal-heading__second-line">
            {data?.item?.activityName ? data.item.activityName : data.name}
          </div>
          <div className="modal__separator" />

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async (values) => handleOnSubmit(values)}
          >
            {({ handleSubmit, errors, values, setFieldValue }) => {
              const minDateEnd =
                values.dateStart &&
                moment(values.dateStart, "DD/MM/YYYY HH:mm:ss").valueOf();

              // const timeEnd = () => {

              // };
              // timeEnd();
              return (
                <form className="add-user__form form" onSubmit={handleSubmit}>
                  <Field
                    errors={errors}
                    label="Czas rozpoczęcia"
                    name="dateStart"
                    component={CalendarInput}
                    withHours
                  />
                  <Field
                    errors={errors}
                    label="Czas zakończenia"
                    name="dateEnd"
                    component={CalendarInput}
                    minDate={minDateEnd}
                    withHours
                  />
                  <Field
                    errors={errors}
                    label="Komentarz"
                    name="comment"
                    component={Textarea}
                  />
                  <div className="modal-buttons add-user__actions">
                    <Button stroke onClick={() => onClose()} label="Anuluj" />
                    <Button type="submit" label="Potwierdź" />
                  </div>
                </form>
              );
            }}
          </Formik>
        </>
      </Modal>
    </>
  );
};

export default WorkingTimeForm;
