import { useState } from "react";
import { Field, Formik } from "formik";
import {
  TypeOfInstalationGarage,
  SingleSectionElement,
  BuildingMaterialComponent,
  MountingAccessories,
} from "features/addingMeasurement";
import { GarageDoorsInstallationInfoData } from "hooks/measurements";

import { ScrollToError } from "utils";
import { VALIDATION_MESSAGES } from "enums";
import { Select, Input, Switch, Textarea, Button } from "components";
import * as Yup from "yup";
import { useSearchParams } from "react-router-dom";

interface GarageDoorInstallationInformationType {
  measurementUuid: string | null;
  measurementInfo: any;
  step: number;
  setStep(step: number): void;
  prevStep: () => void;
}

const GarageDoorInstallationInformation = ({
  measurementUuid,
  measurementInfo,
  setStep,
  step,
  prevStep,
}: GarageDoorInstallationInformationType) => {
  const [isValidated, setIsValidated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const handleOnSubmit = async (values: any) => {
    await editInstallationInformation(values);
  };

  const initialValue = measurementInfo?.measurementInstallationInfo;

  const { mutate: editInstallationInformation } =
    GarageDoorsInstallationInfoData({
      onSuccess: () => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("measurementPage", "3");
        setSearchParams(newSearchParams, { replace: true });
        setStep(step + 1);
      },
    });

  const typesOfSpoutLevelOptions = [
    {
      value: "fromTheProject",
      label: "Z projektu",
    },
    {
      value: "real",
      label: "Rzeczywisty",
    },
    {
      value: "specifiedByInvestor",
      label: "określony przez inwestora",
    },
    {
      value: "specifiedByContractor",
      label: "Określony przez wykonawcę",
    },
  ];

  const validationSchema = Yup.object().shape({
    typeOfInstallation: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    spoutLevel: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    spoutLevelValue: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    numberOfAssemblyTeams: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    plannedAssemblyTime: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    theBuildingIsMadeOf: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test("is-other-check", VALIDATION_MESSAGES.REQUIRED, function (value) {
        const { theBuildingIsMadeOfComment } = this.parent;
        if (value === "other" && !theBuildingIsMadeOfComment) {
          return false;
        }
        return true;
      }),
    theBuildingIsMadeOfComment: Yup.string(),
    isTheOpeningCorrect: Yup.string().test(
      "is-required-when-toImprove",
      VALIDATION_MESSAGES.REQUIRED,
      function (value) {
        const { isTheOpeningCorrectComment } = this.parent;
        if (value === "false" && !isTheOpeningCorrectComment) {
          return false;
        }
        return true;
      }
    ),
  });

  return (
    <div>
      <div className="am-heading">Informacje montażowe</div>
      <Formik
        initialValues={{
          measurementUuid: measurementUuid,
          typeOfInstallation: initialValue?.typeOfInstallation || [],
          spoutLevel: initialValue?.spoutLevel || "",
          plannedAssemblyTime: initialValue?.plannedAssemblyTime || "",
          numberOfAssemblyTeams: initialValue?.numberOfAssemblyTeams || "",
          spoutLevelValue: initialValue?.spoutLevelValue || "",
          theBuildingIsMadeOf: initialValue?.theBuildingIsMadeOf || [],
          isTheOpeningCorrect:
            initialValue?.isTheOpeningCorrect === false ? false : true,
          isTheOpeningCorrectComment:
            initialValue?.isTheOpeningCorrectComment || "",
          comments: initialValue?.comments || "",
          installationElementsUuids:
            initialValue?.installationElementsUuids || [],
          theBuildingIsMadeOfComment:
            initialValue?.theBuildingIsMadeOfComment || "",
        }}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={isValidated}
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({ handleSubmit, errors, values, submitCount, touched }) => {
          if (submitCount > 0) {
            setIsValidated(true);
          }
          const isTheOpeningCorrect = values.isTheOpeningCorrect;
          return (
            <form className={`amfs__form form `} onSubmit={handleSubmit}>
              <ScrollToError />
              <Field
                values={values}
                errors={errors}
                label="Nazwa materiału"
                name="typeOfInstallation"
                component={TypeOfInstalationGarage}
              />
              <SingleSectionElement
                touched={touched}
                name="spoutLevel"
                errors={errors}
              >
                <Field
                  label="Poziom wylewki"
                  name="spoutLevel"
                  type="number"
                  options={typesOfSpoutLevelOptions}
                  component={Select}
                  white
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="spoutLevelValue"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  label="Wartość poziomu wylewki"
                  name="spoutLevelValue"
                  component={Input}
                  rightPlaceholder="mm"
                  type="number"
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name="plannedAssemblyTime"
                errors={errors}
                className="padding-top-separator"
              >
                <Field
                  label="Planowany czas montażu (w godzinach)"
                  name="plannedAssemblyTime"
                  maxLength={6}
                  type="number"
                  rightPlaceholder="h"
                  component={Input}
                />
              </SingleSectionElement>
              <SingleSectionElement
                className="single-section-element--margin-top"
                touched={touched}
                name="numberOfAssemblyTeams"
                errors={errors}
              >
                <Field
                  label="Ilość osób potrzebnych do montażu"
                  name="numberOfAssemblyTeams"
                  type="number"
                  component={Input}
                  maxLength={3}
                />
              </SingleSectionElement>
              <SingleSectionElement
                touched={touched}
                name={["isTheOpeningCorrect", "isTheOpeningCorrectComment"]}
                errors={errors}
              >
                <Field
                  checked={values.isTheOpeningCorrect}
                  label={`Czy otwór jest prawidłowy?`}
                  name="isTheOpeningCorrect"
                  component={Switch}
                />
                {!isTheOpeningCorrect && (
                  <Field
                    errors={errors}
                    label="Komentarz"
                    name="isTheOpeningCorrectComment"
                    component={Textarea}
                  />
                )}
              </SingleSectionElement>
              <Field
                errors={errors}
                name="theBuildingIsMadeOf"
                component={BuildingMaterialComponent}
              />
              <Field
                optional
                type={measurementInfo?.typeOfMeasurement}
                errors={errors}
                name="installationElementsUuids"
                component={MountingAccessories}
              />
              <Field
                className="am-margin-top"
                errors={errors}
                label="Dodatkowe uwagi"
                name="comments"
                optional
                component={Textarea}
                maxLength={250}
              />
              <div className="am__buttons">
                <Button
                  stroke
                  onClick={() => prevStep()}
                  label="Poprzedni krok"
                />
                <Button onClick={() => handleSubmit()} label="Dalej" />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default GarageDoorInstallationInformation;
