import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

interface Team {
  start: string;
  end: string;
  employeeUuids: string[];
}

interface DateEntry {
  date: string;
  teams: Team[];
}

interface UpdateInstallationGroupDetailsType {
  groupUuid: string;
  measurementNames: string[];
  comment: string;
  dates: DateEntry[];
}

const useUpdateInstallationGroup = (
  options?: UseMutationOptions<any, Error, UpdateInstallationGroupDetailsType>
) => {
  return useMutation({
    ...options,
    mutationKey: ['updateInstallationGroup'],
    mutationFn: async ({ groupUuid, measurementNames, comment, dates }) => {
      return axiosInstance.put(`/orders/determining-installation/update`, {
        groupUuid,
        measurementNames,
        comment,
        dates,
      }).then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie aktualizacji umawianego montażu...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'update-installation-group',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      Toast({
        message: 'Zaktualizowano montaż',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'update-installation-group',
      });
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacjiumawianego montażu',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'update-installation-group',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default useUpdateInstallationGroup;
