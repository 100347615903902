enum ARCHIVAL_TYPE {
  MEASUREMENTS_ATTEPMT = 'measurementsAttempt', // Próba wykonania pomiaru
  MEASUREMENTS_TAKEN= 'measurementsTaken', // Wykonanie pomiaru
  VERIFICATION_VISIT = 'verificationVisit', // Przeprowadzenie weryfikacji
  INSTALLATION_ATTEMPT = 'installationAttempt', // Próba wykonania montażu
  INSTALLATION_PLANNED = 'installationPlanned', // zaplanowany monta
  INSTALLATION_DONE = 'installationDone', // Wykonanie montażu
  ADDITIONAL_VISIT = 'additionalVisit', // dodatkoway montaż
}

export default ARCHIVAL_TYPE;
