import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';
import { useSearchParams } from "react-router-dom";

// Typy danych
export type InterimDateUpdateData = {
  measurementUuid: string;
  type: 'custom' | 'regular'; 
  dateStart: string; 
  dateEnd: string; 
  numberOfInstallationCrews: number;
};

const useUpdateInterimDate = (
  options?: UseMutationOptions<any, Error, InterimDateUpdateData>
) => {
  const [searchParams] = useSearchParams();
  const orderUuid = searchParams.get("orderUuid");
  return useMutation({
    ...options,
    mutationKey: ['updateInterimDate'],
    mutationFn: async (data: InterimDateUpdateData) => {
      return axiosInstance
        .put('/installation/initial-date/update/', data)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Aktualizowanie wstępnego terminu...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'update-interim-date',
      });
      options?.onMutate?.(variables);
    },
    onSuccess: async (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['orderDetails', orderUuid] });
      Toast({
        message: 'Wstępny termin został zaktualizowan pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'update-interim-date',
      });
      options?.onSuccess?.(data, variables, context);
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji wstępnego terminu.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'update-interim-date',
      });
      options?.onError?.(error, variables, context);
    },
  });
};

export default useUpdateInterimDate;
