import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';

export type OrderEditData = {
  uuid: string;
  name: string;
  clientName: string;
  phonePrefixUuid: string;
  phoneNumber: number;
  email?: string;
  address: string;
};

const usePutOrderEdit = (
  options?: UseMutationOptions<any, Error, OrderEditData>
) => {
  return useMutation({
    ...options,
    mutationKey: ['editOrder'],
    mutationFn: async (data: OrderEditData) => {
      const { uuid, ...updateData } = data;
      return axiosInstance
        .put(`orders/edit/${uuid}`, updateData)
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Edycja danych zlecenia... ',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'edit-order',
      });
      if (options && options.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: ['scheduledOrders'] });
      Toast({
        message: 'Dane zlecenia zostały zaktualizowane pomyślnie.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'edit-order',
      });
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas edycji danych zlecenia.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'edit-order',
      });
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutOrderEdit;
