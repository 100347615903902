/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { PortalWithState } from "react-portal";
import "./modal.scss";

interface ModalType {
  children: React.ReactNode;
  side?: "right" | "center";
  className?: string;
  isOpened: boolean;
  onClose: () => void;
  clean?: boolean;
  withoutBackgroud?: boolean;
}

const Modal = ({
  children,
  side = "center",
  clean = false,
  className,
  isOpened,
  onClose,
  withoutBackgroud,
}: ModalType) => {
  const [isOpen, setIsOpen] = useState(isOpened);

  useEffect(() => {
    isOpened ? setIsOpen(true) : setTimeout(() => setIsOpen(false), 200);
  }, [isOpened]);

  const modalClass = classNames("modal", className, {
    "modal--right": side === "right",
    "modal--center": side === "center",
    "modal--hidden": !isOpened,
  });

  const modalContentClass = classNames("modal__content", className, {
    "modal__content--right": side === "right",
    "modal__content--center": side === "center",
    "modal__content--center-hidden": side === "center" && !isOpened,
    "modal__content--right-hidden": side === "right" && !isOpened,
  });

  const modalContentRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    const toastElement = (event.target as HTMLElement).closest(
      ".Toastify__toast"
    );

    const fullPage = (event.target as HTMLElement).closest(".full-page");

    const deleteActivityElement = (event.target as HTMLElement).closest(
      ".delete-invoice"
    );

    if (toastElement || deleteActivityElement || fullPage) {
      return;
    }

    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(event.target as Node)
    ) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <PortalWithState
      closeOnEsc={true}
      closeOnOutsideClick={false}
      defaultOpen={isOpen}
      onClose={() => onClose()}
    >
      {({ portal }) => (
        <>
          {portal(
            <>
              <div className={modalClass}>
                <div className="modal__content--position">
                  <div className={modalContentClass} ref={modalContentRef}>
                    {children}
                  </div>
                </div>
              </div>
              {!clean && !withoutBackgroud && (
                <div className="modal-background" />
              )}
            </>
          )}
        </>
      )}
    </PortalWithState>
  );
};

export default Modal;
