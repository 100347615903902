import { Button, SVG } from "components";
import { USER_ROLE, ORDER_ENUM, SVG_TYPE, ROUTE_ENUM } from "enums";
import { OrderListType, OrderType } from "types/OrderTypes";
import { MEASUREMENTS_TYPE_TRANSLATIONS } from "enums";
import { hasAccess, hasOrderStatus } from "utils";
import { useNavigate } from "react-router";
import "./determined-and-arranged-installation.scss";
import { useSearchParams } from "react-router-dom";
import { InterimDate } from "features/orders";

interface DeterminedAndArrangedInstallationProps {
  orderDetails: OrderType;
  handleClickAppointment?: (type: string, groupUuid?: any) => void;
  userRole?: string;
  order: OrderListType;
}

const DeterminedAndArrangedInstallation = ({
  orderDetails,
  handleClickAppointment,
  userRole,
  order,
}: DeterminedAndArrangedInstallationProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const groupUuidUrl = searchParams.get("groupUuid");
  const isActiveOrder = document.URL.includes("active-order");
  const { ACTIVE_ORDER } = ROUTE_ENUM;
  const { admin, fitter, scheduleManager, measurer } = USER_ROLE;
  const {
    FIXED_INSTALLATION,
    INSTALLATION_IN_PROGESS,
    INSTALLATION_TO_BE_RELEASED,
  } = ORDER_ENUM;
  const {
    measurementsArrangedInstallations,
    measurementsToBeDetermined,
    status,
    customMeasurements,
  } = orderDetails;
  const orderStatusAccess = hasOrderStatus(status, [
    INSTALLATION_TO_BE_RELEASED,
    FIXED_INSTALLATION,
    INSTALLATION_IN_PROGESS,
  ]);
  const roleAccess = hasAccess(userRole, [admin, fitter, scheduleManager]);
  const isFitter = hasAccess(userRole, [fitter]);
  const isMeasurer = hasAccess(userRole, [measurer]);
  const isScheduler = hasAccess(userRole, [scheduleManager]);
  const editAccess =
    hasAccess(userRole, [scheduleManager]) && handleClickAppointment;
  const showSection = orderStatusAccess && roleAccess;

  const handleClickInstallation = () => {
    navigate(
      `${ACTIVE_ORDER}?page=${page}&orderUuid=${order.uuid}&tab=${status}${
        order.groupUuid ? `&groupUuid=${order.groupUuid}` : ""
      }`
    );
  };

  const isActiveHandleClickInstallation =
    (isFitter || isMeasurer) && !isActiveOrder;

  function formatDateRange(initialDate: { start: string; end: string }) {
    const { start, end } = initialDate;

    const [startDay, startMonth, startYear] = start.trim().split("/");
    const [endDay, endMonth, endYear] = end.trim().split("/");

    if (start === end) {
      return start;
    } else if (startMonth === endMonth && startYear === endYear) {
      return `${startDay}-${endDay}/${startMonth}/${startYear}`;
    } else {
      return `${start} - ${end}`;
    }
  }

  return (
    <>
      {showSection && (
        <>
          {!isFitter && (
            <>
              {measurementsToBeDetermined &&
                measurementsToBeDetermined.length > 0 && (
                  <div className="determined-and-arranged-installation">
                    {isScheduler && <div className="am-separator" />}
                    <div className="modal__information-header determined-and-arranged-installation__header">
                      Montaże do ustalenia
                      {editAccess && (
                        <Button
                          size="small"
                          label="Umów"
                          onClick={() => handleClickAppointment("")}
                        />
                      )}
                    </div>
                    <div className="installation-planning">
                      {measurementsToBeDetermined.map((measurement, index) => {
                        const moreThanOne = index > 0;
                        const {
                          plannedAssemblyTime,
                          numberOfAssemblyTeams,
                          type,
                          counter,
                          initialDate,
                        } = measurement;
                        return (
                          <div
                            key={index}
                            className={`installation-planning__top ${
                              moreThanOne
                                ? "installation-planning__top--more"
                                : ""
                            }`}
                          >
                            <div className="installation-planning__left">
                              <div className="installation-planning__label">
                                <span>{counter}</span>
                                {MEASUREMENTS_TYPE_TRANSLATIONS[type]}
                              </div>
                              <div className="installation-planning__numbers">
                                <div className="installation-planning__number-item">
                                  <SVG type={SVG_TYPE.CLOCK} />{" "}
                                  {plannedAssemblyTime}h
                                </div>
                                <div className="installation-planning__number-item">
                                  <SVG type={SVG_TYPE.USER} />{" "}
                                  {numberOfAssemblyTeams}
                                </div>
                                {initialDate?.numberOfInstallationCrews && (
                                  <div className="installation-planning__number-item">
                                    <SVG type={SVG_TYPE.USERS_BOLD} />{" "}
                                    {initialDate.numberOfInstallationCrews}
                                  </div>
                                )}
                                {initialDate && isScheduler && (
                                  <div className="installation-planning__initial-date">
                                    <SVG type={SVG_TYPE.CALENDAR_SECOND} />{" "}
                                    {formatDateRange(initialDate)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <InterimDate
                              measurement={measurement}
                              isCustom={customMeasurements}
                            />
                          </div>
                        );
                      })}
                    </div>
                    {isScheduler &&
                      measurementsArrangedInstallations &&
                      measurementsArrangedInstallations.length > 0 && (
                        <div className="am-separator" />
                      )}
                  </div>
                )}
            </>
          )}
          {measurementsArrangedInstallations &&
            measurementsArrangedInstallations.length > 0 && (
              <>
                <div className="determined-and-arranged-installation">
                  <div className="modal__information-header determined-and-arranged-installation__header">
                    {isFitter ? "Montaż ustalony" : "Montaże ustalone"}
                    {isActiveHandleClickInstallation && (
                      <Button
                        size="small"
                        label="Wykonaj"
                        onClick={() => handleClickInstallation()}
                      />
                    )}
                  </div>
                  <div className=" determined-and-arranged-installation__installation-list">
                    {measurementsArrangedInstallations
                      .filter(({ groupUuid }) => {
                        if (isFitter) {
                          return (
                            groupUuid === order.groupUuid ||
                            groupUuid === groupUuidUrl
                          );
                        }
                        return true;
                      })
                      .map(
                        ({
                          groupUuid,
                          measurements,
                          dates,
                          schedulerComments,
                          additionalVisit,
                          additionalVisitComments,
                          groupStatus,
                        }) => {
                          const isCompleted = groupStatus === "completed";
                          return (
                            <div className="installation-planning">
                              <div className="determined-and-arranged-installation__installation-item">
                                <div className="determined-and-arranged-installation__installation-item__left">
                                  {measurements.map(
                                    ({ type, counter }, index) => {
                                      const moreThanOne = index > 0;
                                      return (
                                        <div
                                          key={index}
                                          className={`installation-planning__top ${
                                            moreThanOne
                                              ? "installation-planning__top--more"
                                              : ""
                                          }`}
                                        >
                                          <div className="installation-planning__left">
                                            <div className="installation-planning__label">
                                              <span>{counter}</span>

                                              {
                                                MEASUREMENTS_TYPE_TRANSLATIONS[
                                                  type
                                                ]
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                {editAccess && !isCompleted && (
                                  <div className="determined-and-arranged-installation__installation-item__right">
                                    <Button
                                      size="small"
                                      label="Edytuj"
                                      onClick={() =>
                                        handleClickAppointment(
                                          "edit",
                                          groupUuid
                                        )
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                              {dates.map(({ date, teams }, index) => {
                                const isMoreThanOne = index > 0;
                                return (
                                  <>
                                    <div className="determined-and-arranged-installation__date">
                                      {isMoreThanOne && (
                                        <div className="am-separator" />
                                      )}
                                      <div className="determined-and-arranged-installation__date-content">
                                        <p>Termin</p>
                                        <span>{date}</span>
                                      </div>
                                    </div>
                                    {teams.map(({ start, end, employees }) => (
                                      <div className="determined-and-arranged-installation__teams">
                                        <div className="determined-and-arranged-installation__teams__item">
                                          <div className="determined-and-arranged-installation__teams__item-label">
                                            Przypisani pracownicy
                                          </div>
                                          <div className="determined-and-arranged-installation__teams__item-workers">
                                            {employees.map(
                                              (
                                                { firstName, lastName },
                                                index
                                              ) => {
                                                const isMoreThanOneAndNotLast =
                                                  index + 1 < employees.length;
                                                return (
                                                  <div className="determined-and-arranged-installation__teams__item-employee">
                                                    {firstName} {lastName}
                                                    {isMoreThanOneAndNotLast
                                                      ? ", "
                                                      : ""}
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                        <div className="determined-and-arranged-installation__teams__item determined-and-arranged-installation__teams__item--hours">
                                          <div className="determined-and-arranged-installation__teams__item-label">
                                            Przedział godzinowy
                                          </div>
                                          <div className="determined-and-arranged-installation__teams__item-hours">
                                            {start} - {end}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                    {additionalVisit ? (
                                      <div className="determined-and-arranged-installation__teams">
                                        <div className="additional-appointment">
                                          Komentarz - spotkanie uzupełniające
                                        </div>
                                        <div className="additional-appointment__comment">
                                          {additionalVisitComments}
                                        </div>
                                      </div>
                                    ) : null}
                                    {schedulerComments && (
                                      <>
                                        <div className="am-separator determined-and-arranged-installation__comment-separator" />
                                        <div className="determined-and-arranged-installation__date-content">
                                          <p>Komentarz</p>
                                        </div>
                                        <div className="determined-and-arranged-installation__comment">
                                          {schedulerComments}
                                        </div>
                                      </>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
                {isScheduler && <div className="am-separator" />}
              </>
            )}
        </>
      )}
    </>
  );
};

export default DeterminedAndArrangedInstallation;
