import { Button, SkeletonRow, SVG } from "components";
import { SVG_TYPE } from "enums";
import { isMobile } from "utils";
import { FileType } from "types/OrderTypes";
import { isDeleteActive } from "../utils/isDeleteActive";
import { PHOTOS_ENUM_TYPE } from "types/PhotosTypes";
import { useSearchParams } from "react-router-dom";

interface PhotosSectionProps {
  label: string;
  setActivePhoto: (activePhoto: number) => void;
  setIsModalOpen: (value: boolean) => void;
  photos: FileType[] | undefined;
  uploadedFiles: string[];
  uploadingFiles: { id: string; file: File }[];
  fileInputRef: React.RefObject<HTMLInputElement>;
  handleFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddPhotos: () => void;
  handleDeleteFile: (uuid: string) => void;
  handleRemoveFailedUpload: (id: string) => void;
  retryFailedUpload: (id: string) => void;
  failedUploads: { id: string; file: File }[];
  orderStatus: string;
  photosType: PHOTOS_ENUM_TYPE | PHOTOS_ENUM_TYPE[];
  activePhoto: number | null;
  userRole: string | undefined;
  adding: boolean;
}

const PhotosSection = ({
  label,
  setIsModalOpen,
  photos,
  uploadedFiles,
  uploadingFiles,
  fileInputRef,
  handleFileSelect,
  handleAddPhotos,
  handleDeleteFile,
  handleRemoveFailedUpload,
  retryFailedUpload,
  failedUploads,
  orderStatus,
  photosType,
  setActivePhoto,
  userRole,
  adding,
  activePhoto,
}: PhotosSectionProps) => {
  const [searchParams] = useSearchParams();
  const groupUuid = searchParams.get("groupUuid");
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const totalPhotosCount = (photos?.length || 0) + failedUploads.length;
  const maxPhotosToShow = photosType === null || isMobile() ? 4 : 6;
  const combinedPhotos = [
    ...(photos || []).map((photo) => ({ ...photo, isFailed: false })),
    ...failedUploads.map((upload) => ({ ...upload, isFailed: true })),
  ].slice(0, maxPhotosToShow);

  const moreSix = totalPhotosCount > 6;

  const handleClickPhoto = (photoNumber: number) => {
    setIsModalOpen(true);
    setTimeout(() => {
      setActivePhoto(photoNumber);
    }, 100);
  };

  return (
    <>
      <div className="active-order__bar">
        {photosType && adding ? (
          <div className="active-order__bar-heading photos-active-order__bar-heading">
            <div
              className="active-order__heading active-order__heading--active"
              onClick={() => handleOpenModal()}
            >
              {label}
            </div>
            <Button
              stroke
              svgType={SVG_TYPE.PLUS}
              size="medium"
              label={`Dodaj zdjęcie`}
              onClick={() => handleAddPhotos()}
            />
          </div>
        ) : (
          <>
            {combinedPhotos.length > 0 && (
              <div className="modal__information-header active-order--photos modal__information-header--active">
                <span onClick={() => handleOpenModal()}>{label}</span>
              </div>
            )}
          </>
        )}

        <div
          className={`photos-active-order__list ${
            moreSix ? "photos-active-order--space-between" : ""
          }`}
        >
          {combinedPhotos.map(
            (
              {
                isFailed,
                id,
                file,
                uuid,
                url,
                type,
                orderInstallationUuid,
              }: any,
              index
            ) => {
              const showDelete = isDeleteActive(
                type,
                orderStatus,
                userRole,
                orderInstallationUuid === groupUuid
              );
              return (
                <>
                  {isFailed ? (
                    <div
                      className="photos-active-order__item failed-upload"
                      key={id}
                    >
                      <img src={URL.createObjectURL(file)} alt="" />
                      <div className="failed-upload__background" />
                      <div className="failed-upload__actions">
                        <div
                          className="failed-upload__action-item"
                          onClick={() => handleRemoveFailedUpload(id)}
                        >
                          <SVG type={SVG_TYPE.BIN} />
                        </div>
                      </div>
                      <div className="failed-upload__center">
                        <div
                          className="failed-upload__center-item"
                          onClick={() => retryFailedUpload(id)}
                        >
                          <SVG className="visible" type={SVG_TYPE.RETRY} />
                        </div>
                      </div>
                      <div className="failed-upload__top">
                        <SVG className="hide" type={SVG_TYPE.WARNING_FILL} />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`photos-active-order__item ${
                        photosType === null
                          ? "photos-active-order__item--order-preview"
                          : ""
                      }`}
                      key={`uploaded-${index}`}
                    >
                      <div className="failed-upload__actions">
                        {showDelete && (
                          <div
                            className="failed-upload__action-item"
                            onClick={() => handleDeleteFile(uuid)}
                          >
                            <SVG type={SVG_TYPE.BIN} />
                          </div>
                        )}
                      </div>
                      <img
                        onClick={() => handleClickPhoto(index)}
                        src={url}
                        alt=""
                      />
                    </div>
                  )}
                </>
              );
            }
          )}
          {combinedPhotos.length < maxPhotosToShow &&
            !(totalPhotosCount > maxPhotosToShow) &&
            uploadingFiles.map(({ id, file }) => (
              <div className="photos-active-order__item" key={id}>
                <SkeletonRow height={58} />
              </div>
            ))}
          {totalPhotosCount > maxPhotosToShow && (
            <div
              className={`photos-active-order__item photos-active-order__item--active ${
                photosType === null
                  ? "photos-active-order__item--order-preview"
                  : ""
              }`}
              onClick={() => handleOpenModal()}
            >
              + {totalPhotosCount - maxPhotosToShow}
            </div>
          )}
        </div>
      </div>

      <input
        type="file"
        multiple
        ref={fileInputRef}
        style={{ display: "none" }}
        accept=".jpeg, .jpg, .png"
        onChange={handleFileSelect}
      />
    </>
  );
};

export default PhotosSection;
