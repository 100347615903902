import { FILE_ENUM, ORDER_ENUM, USER_ROLE } from "enums";
import { hasAccess } from "utils";

export const isDeleteActive = (
  fileType: string | null,
  orderStatus: string,
  userRole: string | undefined,
  isInstallationUuid: boolean,
): boolean => {
  const { MEASUREMENTS_PHOTO, INSTALLATION_PHOTO, ADDITIONAL_VISIT_CURRENT_PHOTO } = FILE_ENUM;
  const {
    FIXED_MEASUREMENTS,
    FIXED_INSTALLATION,
    INSTALLATION_TO_BE_RELEASED,
  } = ORDER_ENUM;
  const { fitter, measurer } = USER_ROLE
  const isFitter = userRole === fitter
  const isFitterOrMeasurer = hasAccess(userRole, [fitter, measurer])

  return ( 
    (isFitterOrMeasurer && (orderStatus === FIXED_INSTALLATION) && fileType === ADDITIONAL_VISIT_CURRENT_PHOTO) || 
    (isFitterOrMeasurer && fileType === MEASUREMENTS_PHOTO && orderStatus === FIXED_MEASUREMENTS) ||
    (isFitter && isInstallationUuid && (orderStatus === INSTALLATION_TO_BE_RELEASED || orderStatus === FIXED_INSTALLATION) && fileType === INSTALLATION_PHOTO) 
  );
};