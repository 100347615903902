/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FloatingMenu, Button } from "components";
import {
  OrderPreview,
  OrdersTable,
  AddOrders,
  ArchiveOrder,
} from "features/orders";
import {
  ChecklistTable,
  ChecklistPreview,
  EditChecklist,
} from "features/checklist";
import { ManageActivityList, ActivityTable } from "features/activity";
import { SubMenuItems } from "./utils";
import { isMobile } from "utils";
import { OrderListType } from "types/OrderTypes";
import { useGetMe } from "hooks/auth";
import { USER_ROLE } from "enums";

const Orders = () => {
  const { data: authorizedUser } = useGetMe();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFromUrl = searchParams.get("page")
    ? parseInt(searchParams.get("page") ?? "1", 10)
    : 1;
  const [page, setPage] = useState(pageFromUrl);
  const [activeRowId, setActiveRowId] = useState("");
  const tabFromUrl = searchParams.get("tab");
  const isInitialAddOrdre = searchParams.get("add-order");
  const userRole = authorizedUser?.role;
  const isScheduleManager = userRole === USER_ROLE.scheduleManager;
  const isAdmin = userRole === USER_ROLE.admin;
  const isMeasurer = userRole === USER_ROLE.measurer;
  const isFitter = userRole === USER_ROLE.fitter;
  const isOrders = tabFromUrl === "orders";
  const [activeFloatingMenuItem, setActiveFloatingMenuItem] = useState<any>(
    isScheduleManager || isAdmin
      ? tabFromUrl
        ? SubMenuItems(userRole).find((item) => item.value === tabFromUrl)
        : SubMenuItems(userRole)[0]
      : ""
  );
  const [modalManage, setModalManage] = useState({
    type: "",
    order: ({} as OrderListType) || {},
  });
  useEffect(() => {
    if (activeFloatingMenuItem) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("tab", activeFloatingMenuItem?.value);
      setSearchParams(newSearchParams);
    }
  }, [activeFloatingMenuItem?.value]);

  useEffect(() => {
    if (isInitialAddOrdre === "true") {
      setTimeout(() => {
        setAddOrder();
      }, 300);
    }
  }, []);

  const isActivityList = activeFloatingMenuItem?.value === "activityList";
  const isChecklist = activeFloatingMenuItem?.value === "checklistInstallation";
  const isEditChecklist = modalManage.type === "editChecklist";
  const isAddOrder = modalManage.type === "addOrder";
  const isShowOrderPreview = modalManage.type === "orderPreview";
  const isShowChecklistPreview = modalManage.type === "checklistPreview";
  const isArchiveOrderPreview = modalManage.type === "archive";
  const isActivityListOpened =
    modalManage.type === "activityList" || modalManage.type === "editActivity";
  const isActivityListActive = isAdmin && isActivityList;

  const clearModalManage = () => {
    setTimeout(() => {
      setModalManage({
        type: "",
        order: {
          phoneNumber: "",
          uuid: "",
          id: 0,
          name: "",
          clientName: "",
          status: "fixedInstallation",
        },
      });
      setActiveRowId("");
    }, 100);
    setTimeout(() => {
      const search = window.location.search;
      const searchParams = new URLSearchParams(search);
      const page = searchParams.get("page");
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("orderUuid");
      newSearchParams.delete("groupUuid");
      newSearchParams.set("page", page || pageFromUrl.toString());
      setSearchParams(newSearchParams);
    }, 200);
  };
  const setOrderPreview = (order: OrderListType) => {
    setModalManage({ type: "orderPreview", order });
  };

  const setCheckListPreview = (order: OrderListType) => {
    setModalManage({ type: "checklistPreview", order });
  };

  const setEditChecklist = () => {
    setModalManage({ type: "editChecklist", order: modalManage.order });
  };

  const setAddOrder = () => {
    setModalManage({ type: "addOrder", order: modalManage.order });
  };

  const setActivityList = () => {
    setModalManage({ type: "activityList", order: modalManage.order });
  };

  const handleSetTab = (item: any) => {
    setPage(1);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("tab", item?.value);
    newSearchParams.set("page", "1");
    setSearchParams(newSearchParams);
  };

  const handleAddFunction = () => {
    isScheduleManager ? setAddOrder() : setActivityList();
    isScheduleManager &&
      setTimeout(() => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("add-order", "true");
        setSearchParams(newSearchParams);
      }, 50);
  };

  return (
    <>
      {isScheduleManager && (
        <AddOrders
          order={modalManage.order}
          isOpen={isAddOrder}
          onClose={() => clearModalManage()}
        />
      )}
      <ArchiveOrder
        isOpen={isArchiveOrderPreview}
        data={modalManage.order}
        onClose={() => clearModalManage()}
      />
      <EditChecklist
        isOpen={isEditChecklist}
        data={modalManage.order}
        onClose={() => clearModalManage()}
      />
      <ChecklistPreview
        isMeasurer={isMeasurer}
        isOpen={isShowChecklistPreview}
        data={modalManage.order}
        onClose={() => clearModalManage()}
        page={pageFromUrl}
        setEditChecklist={setEditChecklist}
      />
      <OrderPreview
        userRole={userRole}
        isOpen={isShowOrderPreview}
        order={modalManage.order}
        onClose={() => clearModalManage()}
        page={pageFromUrl}
      />
      <ManageActivityList
        isMeasurer={isMeasurer}
        isEdit={modalManage.type === "editActivity"}
        isOpen={isActivityListOpened}
        order={modalManage.order}
        onClose={() => clearModalManage()}
        page={pageFromUrl}
      />
      <div className="dashboard-tab-name-with-button dashboard-tab-name-with-button--only-mobile">
        <div className="dashboard-tab-name">Lista zleceń</div>
        {(isActivityListActive || isScheduleManager) && isMobile() && (
          <Button
            className="btn-plus"
            secondary
            label="+"
            onClick={() => handleAddFunction()}
          />
        )}
      </div>
      {!isMeasurer && !isFitter && (
        <div>
          <div className="floating-menu-with-button">
            <FloatingMenu
              items={SubMenuItems(userRole)}
              activeFloatingMenuItem={activeFloatingMenuItem}
              setActiveFloatingMenuItem={setActiveFloatingMenuItem}
              onClick={(item: any) => handleSetTab(item)}
            />
            {(isActivityListActive || isScheduleManager) && !isMobile() && (
              <Button
                secondary={isScheduleManager}
                size="medium"
                onClick={() => handleAddFunction()}
                label="Dodaj +"
              />
            )}
          </div>
        </div>
      )}
      {isActivityList && (
        <ActivityTable
          modalManage={modalManage}
          setModalManage={setModalManage}
        />
      )}
      {((userRole && !isAdmin) || (isAdmin && isOrders)) && (
        <OrdersTable
          setModalManage={setModalManage}
          userRole={userRole}
          activeFloatingMenuItem={activeFloatingMenuItem}
          setOrderPreview={setOrderPreview}
          page={page}
          setPage={setPage}
          activeRowId={activeRowId}
          setActiveRowId={setActiveRowId}
        />
      )}
      {isChecklist && (
        <ChecklistTable
          modalManage={modalManage}
          setModalManage={setModalManage}
          setCheckListPreview={setCheckListPreview}
        />
      )}
    </>
  );
};

export default Orders;
