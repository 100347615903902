export const removeDecimalZeros = (value:any) => {

  if (typeof value !== "string") return value;

  const valueStr = value.toString();

  if (valueStr.endsWith('.00')) {
    return parseInt(valueStr, 10);
  }

  return value;
};