import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from 'axiosConfig';
import { Toast } from 'components';
import { TOAST_TYPES_ENUM } from 'enums';
import { queryClient } from 'App';
import { useSearchParams } from "react-router-dom";

type AdditionalVisitData = {
  groupUuid: string;
  checkbox?: boolean;
  comments?: string;
};

const usePutAdditionalVisit = (
  options?: UseMutationOptions<any, Error, AdditionalVisitData>
) => {
    const [searchParams] = useSearchParams();
    const orderUuid = searchParams.get("orderUuid");
  return useMutation({
    ...options,
    mutationKey: ['putAdditionalVisit'],
    mutationFn: async (data: AdditionalVisitData) => {
      const { groupUuid, checkbox, comments } = data;
      return axiosInstance
        .put(`/installation/additional-visit/${groupUuid}`, {
          checkbox,
          comments,
        })
        .then((res) => res.data);
    },
    onMutate: (variables) => {
      Toast({
        message: 'Przetwarzanie wizyty uzupełniającej...',
        type: TOAST_TYPES_ENUM.LOADING,
        id: 'put-additional-visit',
      });
      if (options?.onMutate) {
        return options.onMutate(variables);
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['orderDetails', orderUuid] });

      Toast({
        message: 'Wizyta uzupełniająca została zaktualizowana.',
        type: TOAST_TYPES_ENUM.SUCCESS,
        id: 'put-additional-visit',
      });
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error, variables, context) => {
      Toast({
        message: 'Błąd podczas aktualizacji wizyty uzupełniającej.',
        type: TOAST_TYPES_ENUM.ERROR,
        id: 'put-additional-visit',
      });
      if (options?.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export default usePutAdditionalVisit;
