import { PortalWithState } from "react-portal";
import { useRef, useEffect } from "react";
import Slider from "react-slick";
import { Button, SVG } from "components";
import { SVG_TYPE } from "enums";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FileType } from "types/OrderTypes";
import { isDeleteActive } from "../utils/isDeleteActive";
import { PHOTOS_ENUM_TYPE } from "types/PhotosTypes";
import { useSearchParams } from "react-router-dom";

interface PhotosModalType {
  isOpen: boolean;
  adding: boolean;
  onClose: () => void;
  photos: FileType[] | undefined;
  type: PHOTOS_ENUM_TYPE | PHOTOS_ENUM_TYPE[];
  handleRemoveFailedUpload: (id: string) => void;
  handleDeleteFile: (uuid: string) => void;
  handleAddPhotos: () => void;
  failedUploads: { id: string; file: File }[];
  retryFailedUpload: (id: string) => void;
  orderStatus: string;
  activePhoto: number | null;
  setActivePhoto: (activePhoto: number) => void;
  userRole: string | undefined;
  label: string;
}

const PhotosModal = ({
  isOpen,
  onClose,
  photos,
  type,
  handleAddPhotos,
  handleDeleteFile,
  failedUploads,
  handleRemoveFailedUpload,
  retryFailedUpload,
  orderStatus,
  userRole,
  activePhoto,
  setActivePhoto,
  adding,
  label,
}: PhotosModalType) => {
  const sliderRef = useRef<Slider | null>(null);
  const [searchParams] = useSearchParams();
  const groupUuid = searchParams.get("groupUuid");

  const handleClickPhoto = (photoNumber: number) => {
    setActivePhoto(photoNumber);
  };

  useEffect(() => {
    if (sliderRef.current && activePhoto) {
      sliderRef.current.slickGoTo(activePhoto);
    }
  }, [activePhoto]);

  const handlePreviewClick = (photoNumber: number) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(photoNumber);
      handleClickPhoto(photoNumber);
    }
  };

  const CustomNextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow`}
        style={{ ...style }}
        onClick={onClick}
      >
        <SVG type={SVG_TYPE.ARROW_BACK} />
      </div>
    );
  };

  const CustomPrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow`}
        style={{ ...style }}
        onClick={onClick}
      >
        <SVG type={SVG_TYPE.ARROW_BACK} />
      </div>
    );
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <>
      <PortalWithState
        closeOnEsc={false}
        closeOnOutsideClick={false}
        defaultOpen={true}
      >
        {({ portal }) => (
          <>
            {portal(
              <>
                {isOpen && (
                  <div className="full-page am">
                    <div className="am__content">
                      <div className="am-top">
                        <Button
                          label="Zamknij"
                          stroke
                          size="medium"
                          svgType={SVG_TYPE.CLOSE}
                          onClick={() => onClose()}
                        />
                      </div>
                      <div className="photos-modal">
                        <div className="photos-modal__top">
                          <div className="photos-modal__label">{label}</div>
                          {type && adding && (
                            <Button
                              label="Dodaj zdjęcia"
                              size="medium"
                              onClick={() => handleAddPhotos()}
                            />
                          )}
                        </div>
                        <div className="photos-modal__carousel">
                          {photos && photos.length > 0 && (
                            <Slider {...sliderSettings} ref={sliderRef}>
                              {photos.map(
                                (
                                  { url, uuid, type, orderInstallationUuid },
                                  index
                                ) => {
                                  const isActive = isDeleteActive(
                                    type,
                                    orderStatus,
                                    userRole,
                                    orderInstallationUuid === groupUuid
                                  );
                                  return (
                                    <div className="slick-item" key={index}>
                                      {isActive && (
                                        <div
                                          className="slick-item--delete"
                                          onClick={() => handleDeleteFile(uuid)}
                                        >
                                          <SVG type={SVG_TYPE.TRASH_SECOND} />
                                        </div>
                                      )}
                                      <img
                                        src={url}
                                        alt=""
                                        style={{
                                          maxWidth: "100%",
                                          height: "auto",
                                        }}
                                      />
                                    </div>
                                  );
                                }
                              )}
                            </Slider>
                          )}
                        </div>
                        {photos && photos.length > 0 && (
                          <div className="photos-modal__previews">
                            {photos.map(
                              (
                                { url, uuid, type, orderInstallationUuid },
                                index
                              ) => {
                                const isActive = isDeleteActive(
                                  type,
                                  orderStatus,
                                  userRole,
                                  orderInstallationUuid === groupUuid
                                );

                                return (
                                  <div
                                    className="photos-modal__previews-item"
                                    key={index}
                                  >
                                    <div
                                      className="photos-modal__previews--onclick"
                                      onClick={() => handlePreviewClick(index)}
                                    />
                                    <img src={url} alt="" />
                                    {isActive && (
                                      <div
                                        className="photos-modal__previews-item--delete"
                                        onClick={() => handleDeleteFile(uuid)}
                                      >
                                        <SVG type={SVG_TYPE.TRASH_SECOND} />
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            )}
                            {failedUploads && failedUploads.length > 0 && (
                              <>
                                {failedUploads.map(({ id, file }, index) => (
                                  <div
                                    className="photos-modal__previews-item failed-upload"
                                    key={index}
                                  >
                                    <div className="failed-upload__background" />
                                    <img
                                      onClick={() => handleClickPhoto(index)}
                                      src={URL.createObjectURL(file)}
                                      alt=""
                                    />

                                    <div className="failed-upload__center">
                                      <div
                                        className="failed-upload__center-item"
                                        onClick={() => retryFailedUpload(id)}
                                      >
                                        <SVG type={SVG_TYPE.RETRY} />
                                      </div>
                                    </div>

                                    <div className="failed-upload__top">
                                      <div>
                                        <SVG type={SVG_TYPE.WARNING_FILL} />
                                      </div>
                                    </div>

                                    <div
                                      className="photos-modal__previews-item--delete"
                                      onClick={() =>
                                        handleRemoveFailedUpload(id)
                                      }
                                    >
                                      <SVG type={SVG_TYPE.TRASH_SECOND} />
                                    </div>
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </PortalWithState>
    </>
  );
};

export default PhotosModal;
