import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";
import { MetaType } from "types/UtilsTypes";
import { OrderListType, OrderStatus } from "types/OrderTypes";

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export default function useGetOrders(status: OrderStatus, page: number = 1, options = {}) {
  return useQuery({
    queryKey: ["orders", status, page],
    queryFn: async () => {
      await delay(1000); 
      return axiosInstance
      .get(`/orders?${status ? `status=${status}&` : ""}page=${page}`)
        .then((res) => res.data);
    },
    select: (data: { orders: { data: OrderListType[], meta: MetaType }}) => data?.orders,
    retry: false,
    ...options,
  });
}
