import { Field, Formik } from "formik";
import { Switch, Button } from "components";
import classNames from "classnames";
import { useAdditionalInstallationVerification } from "hooks/orders";
import { useNavigate } from "react-router";
import { FILE_ENUM, ROUTE_ENUM } from "enums";
import { OrderType } from "types/OrderTypes";

interface AdditionalInstallationVerificationProps {
  uuid: string;
  orderDetails: OrderType;
  setIsErrorPhotos: ({
    isError,
    type,
  }: {
    isError: boolean;
    type: string | string[];
  }) => void;
  additionalVisitCheckList: {
    id: number;
    uuid: string;
    text: string;
    status: number;
  }[];
}

const AdditionalInstallationVerification = ({
  additionalVisitCheckList,
  uuid,
  orderDetails,
  setIsErrorPhotos,
}: AdditionalInstallationVerificationProps) => {
  const navigate = useNavigate();
  const { files } = orderDetails;

  const { mutate: verifyInstallation } = useAdditionalInstallationVerification({
    onSuccess: () => {
      navigate(ROUTE_ENUM.ORDERS_LIST);
    },
  });
  const initialValues = {
    checkList: additionalVisitCheckList.map((item) => ({
      uuid: item.uuid,
      completed: false,
    })),
  };

  const handleOnSubmit = async (values: any) => {
    const hasCurrentPhoto = files.some(
      (file) => file.type === FILE_ENUM.ADDITIONAL_VISIT_CURRENT_PHOTO
    );
    if (!hasCurrentPhoto) {
      setIsErrorPhotos({
        isError: true,
        type: [
          FILE_ENUM.ADDITIONAL_VISIT_CURRENT_PHOTO,
          FILE_ENUM.ADDITIONAL_VISIT_INSTALLATION_PHOTO,
        ],
      });

      return;
    } else {
      const dataToSend = {
        uuid,
        checkListUuids: values.checkList
          .filter((item: any) => item.completed)
          .map((item: any) => item.uuid),
      };
      verifyInstallation(dataToSend);
    }
  };

  return (
    <div className="active-order__bar">
      <div className="active-order__bar-heading verifications__bar-heading">
        <div className="active-order__heading verifications__heading">
          Montaż uzupełniający
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values) => handleOnSubmit(values)}
      >
        {({ handleSubmit }) => (
          <form
            className="add-user__form form verifications__form"
            onSubmit={handleSubmit}
          >
            <div className="verifications__item">
              {additionalVisitCheckList.map(({ uuid, text, status }, index) => {
                const isLastElement =
                  index === additionalVisitCheckList.length - 1;
                const firstElement = index === 0;
                const additionalVerificationClass = classNames(
                  "verifications__additional",
                  {
                    "verifications__additional--first": firstElement,
                    "verifications__additional--last": isLastElement,
                  }
                );
                return (
                  <>
                    {!status && (
                      <div className={additionalVerificationClass}>
                        <Field
                          key={uuid}
                          name={`checkList[${index}].completed`}
                          component={Switch}
                          label={text}
                          first="Wykonano"
                          second="Nie wykonano"
                        />
                      </div>
                    )}
                  </>
                );
              })}
            </div>
            <Button
              label="Zakończ zlecenie"
              className="active-order__measurement-button"
              onClick={handleSubmit}
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AdditionalInstallationVerification;
