import { useQuery } from "@tanstack/react-query";
import axiosInstance from "axiosConfig";

type MeasurementType = {
  label: string;
  value: string;
  type: string;
};

type MeasurementResponseType = {
  typesOfMeasurementsLabel: MeasurementType[];
};

export default function useGetMeasurementTypes(enabled = true ,options = {}) {
  return useQuery({
    queryKey: ["measurementTypes"],
    queryFn: async () => {
      return axiosInstance
        .get("/types-of-measurements")
        .then((res) => res.data);
    },
    select: (data: MeasurementResponseType) => data.typesOfMeasurementsLabel,
    retry: false,
    ...options,
    staleTime: 0, 
    enabled
  });
}
