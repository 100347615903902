import { useState } from "react";
import "./calendar-page.scss";
import { Calendar } from "components";
import moment from "moment";
import { useGetMeasurerSchedule } from "hooks/orders";
import { convertToEvents } from "utils";
import { useGetMe } from "hooks/auth";

const CalendarPage = () => {
  const { data: authorizedUser } = useGetMe();
  const today = moment().format("YYYY-MM-DD");
  const [date, setDate] = useState(today);
  const month = parseInt(date.split("-")[1]) || 5;
  const year = parseInt(today.split("-")[0]);
  const { data: events = [] } = useGetMeasurerSchedule(month, year);
  return (
    <div className="calendar-page">
      <Calendar
        user={authorizedUser}
        events={convertToEvents(events, "#9DD6FF")}
        date={date}
        setDate={setDate}
        main
        size="100%"
      />
    </div>
  );
};

export default CalendarPage;
