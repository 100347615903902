/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Modal, Button, Textarea, Select } from "components";
import { Field, Formik } from "formik";
import { ORDER_ENUM, USER_ROLE, VALIDATION_MESSAGES } from "enums";
import * as Yup from "yup";
import moment from "moment";
import { ArchivalDatesDetails, AdditionalInstallation } from "features/orders";
import "./arranging-measurement-panel.scss";
import { useNavigate } from "react-router";
import { isMobile } from "utils";
import { useSearchParams } from "react-router-dom";
import {
  useGetOrderDetails,
  useAddMeasurementOrder,
  useEditFixedMeasurementOrder,
  useScheduleVerification,
  useScheduleVerificationUpdate,
  useUpdateAdditionalVisit,
} from "hooks/orders";
import {
  generateStartTimeOptions,
  generateEndTimeOptions,
  checkEventsToday,
} from "./utils";
import { meetingAddress } from "features/orders/utils";

interface ArrangingMeasurementPanelType {
  date: string | undefined;
  setIsModalOpen: (isOpen: boolean) => void;
  calendarEvents: any;
  setDate: any;
  user: { label: string; value: string };
  isModalOpen: boolean;
}

const ArrangingMeasurementPanel = ({
  date,
  calendarEvents,
  setDate,
  user,
  isModalOpen,
  setIsModalOpen,
}: ArrangingMeasurementPanelType) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get("uuid");
  const tab = searchParams.get("tab");
  const type = searchParams.get("type");
  const isAdditionalVisit = type === "additionalVisit";
  const isEdit = tab === "fixedMeasurements" || tab === "fixedOrders";
  const [comment, setComment] = useState("");
  const [checkList, setCheckList] = useState<any>([]);

  const { data: orderDetails, isLoading } = useGetOrderDetails(uuid, true);
  const { mutate: addMeasurementOrder } = useAddMeasurementOrder();
  const { mutate: updateAdditionalVisit } = useUpdateAdditionalVisit();
  const { mutate: scheduleVerification } = useScheduleVerification();
  const { mutate: scheduleVerificationUpdate } =
    useScheduleVerificationUpdate();
  const { mutate: editMeasurementOrder } = useEditFixedMeasurementOrder();
  const {
    VERIFICATION_TO_BE_RELEASED,
    FIXED_VERIFICATION,
    MEASUREMENTS_TO_BE_RELEASED,
  } = ORDER_ENUM;
  const isVerified =
    orderDetails?.status === VERIFICATION_TO_BE_RELEASED ||
    orderDetails?.status === FIXED_VERIFICATION;

  const safeDate = date ?? "2024-01-01";
  const dateParts = safeDate.split(" ");
  let startHour = null;
  let endHour = null;
  const [endTimeOptions, setEndTimeOptions] = useState(
    generateEndTimeOptions(checkEventsToday(calendarEvents, date), date)
  );
  const [startTimeOptions, setStartTimeOptions] = useState(
    generateStartTimeOptions(checkEventsToday(calendarEvents, date), date)
  );
  const [selectedStartTime, setSelectedStartTime] = useState<any>("");
  const [selectedEndTime, setSelectedEndTime] = useState<any>("");

  useEffect(() => {
    let eventsToday;
    const datePick = date && date.split(" ")[0];
    if (calendarEvents) {
      eventsToday = checkEventsToday(calendarEvents, datePick);
    }
    const filteredEndOptions = generateEndTimeOptions(eventsToday, datePick);
    const filteredStartOptions = generateStartTimeOptions(
      eventsToday,
      datePick
    );
    setEndTimeOptions(filteredEndOptions);
    setStartTimeOptions(filteredStartOptions);
  }, [calendarEvents, date]);

  useEffect(() => {
    if (dateParts.length > 1) {
      const timeParts = dateParts[1].split("-");
      startHour = moment(
        dateParts[0] + " " + timeParts[0],
        "YYYY-MM-DD HH:mm"
      ).format("HH:mm");
      endHour = moment(
        dateParts[0] + " " + timeParts[1],
        "YYYY-MM-DD HH:mm"
      ).format("HH:mm");
      startHour = startHour === "00:00" ? "" : startHour;
      setSelectedStartTime(startHour);
      endHour = endHour === "00:00" ? "" : endHour;

      setSelectedEndTime(endHour);
    }
  }, [dateParts]);

  useEffect(() => {
    if (orderDetails && !comment) {
      setComment(orderDetails.comments);
    }
  }, [orderDetails]);

  useEffect(() => {
    if (
      orderDetails &&
      orderDetails.additionalVisitCheckList &&
      orderDetails.additionalVisitCheckList.length > 0 &&
      checkList.length === 0
    ) {
      const additionalListNonChecked =
        orderDetails.additionalVisitCheckList.filter(
          (item) => item.status === 0
        );
      const texts = additionalListNonChecked.map((item) => item.text);
      texts.length > 0 && setCheckList(texts);
    }
  }, [orderDetails]);

  let validationSchema = {};

  validationSchema = Yup.object().shape({
    date: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    start: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    end: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  });
  if (isAdditionalVisit) {
    validationSchema = Yup.object().shape({
      date: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
      start: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
      end: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
      checkList: Yup.array()
        .of(Yup.string().required("Pole nie może być puste"))
        .min(1, "Wymagany conajmniej jeden punkt")
        .required("Wymagany conajmniej jeden punkt"),
    });
  }

  const handleOnSubmit = (value: any, form: any) => {
    const start = value.start.split(":");
    const end = value.end.split(":");
    const checkEvents = checkEventsToday(calendarEvents, value.date);
    const startDate = new Date(value.date + "T" + value.start + ":00");
    const endDate = new Date(value.date + "T" + value.end + ":00");

    if (checkEvents.isEventToday) {
      for (let event of checkEvents.events) {
        if (
          (startDate < event.end && startDate >= event.start) ||
          (endDate > event.start && endDate <= event.end) ||
          (startDate <= event.start && endDate >= event.end)
        ) {
          form.setFieldError(
            "start",
            "W podanym terminie jest już zaplanowany inny pomiar. Ustaw inne godziny."
          );
          return;
        }
      }
    }
    const date = {
      uuid,
      date: moment(value.date).format("DD/MM/YYYY"),
      start:
        parseInt(start[1], 10) === 0
          ? `${parseInt(start[0], 10)}`
          : `${parseInt(start[0], 10)}:${parseInt(start[1], 10)}`,
      end:
        parseInt(end[1], 10) === 0
          ? `${parseInt(end[0], 10)}`
          : `${parseInt(end[0], 10)}:${parseInt(end[1], 10)}`,
      comment: value.comment,
      employeeUuid: user.value,
      checkList: value.checkList,
    };
    if (!isAdditionalVisit) delete date.checkList;

    if (isAdditionalVisit) {
      updateAdditionalVisit(date);
    } else if (isVerified) {
      isEdit ? scheduleVerificationUpdate(date) : scheduleVerification(date);
    } else isEdit ? editMeasurementOrder(date) : addMeasurementOrder(date);
  };

  const handleChangeStartTime = (event: any, setFieldValue: any) => {
    const newTime = event.value;

    if (date) {
      const [datePart, timePart] = date.split(" ");
      let newDateTime;

      if (timePart) {
        const timeParts = timePart.split("-");
        const endTimeParts = timeParts[1] ? timeParts[1].split(":") : null;

        const newStartHour = parseInt(newTime.split(":")[0], 10);
        const newStartMinutes = parseInt(newTime.split(":")[1], 10);

        let adjustedEndHour, adjustedEndMinutes;

        if (endTimeParts) {
          adjustedEndHour = parseInt(endTimeParts[0], 10);
          adjustedEndMinutes = parseInt(endTimeParts[1] || "00", 10);
          if (
            newStartHour > adjustedEndHour ||
            (newStartHour === adjustedEndHour &&
              newStartMinutes >= adjustedEndMinutes)
          ) {
            adjustedEndHour = newStartHour + 1;
            adjustedEndMinutes = 0;
          }
        } else {
          adjustedEndHour = newStartHour + 1;
          adjustedEndMinutes = 0;
        }
        if (adjustedEndHour >= 24) {
          adjustedEndHour = 23;
          adjustedEndMinutes = 59;
        }
        const adjustedEndTime = `${adjustedEndHour
          .toString()
          .padStart(2, "0")}:${adjustedEndMinutes.toString().padStart(2, "0")}`;

        newDateTime = `${datePart} ${newTime}-${adjustedEndTime}`;

        setSelectedEndTime(adjustedEndTime);
      } else {
        newDateTime = `${datePart} ${newTime}`;
      }
      setDate(newDateTime);
    }
    setSelectedStartTime(newTime);
  };

  const handleChangeEndTime = (event: any, setFieldValue: any) => {
    const newTime = event.value;

    if (date) {
      const [datePart, timePart] = date.split(" ");
      const timeParts = timePart.split("-");
      const startTimeParts = timeParts[0].split(":");
      let startHour = parseInt(startTimeParts[0], 10);
      let startMinutes = parseInt(startTimeParts[1] || "00", 10);

      const newHour = parseInt(newTime.split(":")[0], 10);
      const newMinutes = parseInt(newTime.split(":")[1], 10);
      const isNewTimeFullHour = newMinutes === 0;
      if (
        newHour < startHour ||
        (newHour === startHour && newMinutes < startMinutes)
      ) {
        startHour = isNewTimeFullHour ? newHour - 1 : newHour;
        startMinutes = 0;
      }
      if (startHour < 0) startHour = 0;
      const adjustedStartTime = `${startHour
        .toString()
        .padStart(2, "0")}:${startMinutes.toString().padStart(2, "0")}`;
      const newDateTime = `${datePart} ${adjustedStartTime}-${newTime}`;
      setDate(newDateTime);
      setSelectedStartTime(adjustedStartTime);
    }
    setSelectedEndTime(newTime);
  };

  const measurementDate = () => {
    return (
      <>
        <div className="modal__information-header amp__measurement-date">
          {isAdditionalVisit
            ? "Termin montażu uzupełniającego"
            : meetingAddress(
                isVerified
                  ? VERIFICATION_TO_BE_RELEASED
                  : MEASUREMENTS_TO_BE_RELEASED
              )}
        </div>
        <div className="modal__information-descript">
          {moment(date && date.split(" ")[0]).format("DD/MM/YYYY")}
        </div>
      </>
    );
  };

  const handleCancelMeasurement = () => {
    isMobile() ? setIsModalOpen(false) : navigate(-1);
  };

  const initialValues = {
    date: moment(dateParts[0], "YYYY-MM-DD").format("YYYY-MM-DD"),
    start: selectedStartTime ? selectedStartTime : "",
    end: selectedEndTime ? selectedEndTime : "",
    comment: comment,
    checkList: checkList,
  };

  return (
    <div className="amp">
      {!isLoading && orderDetails && (
        <Modal
          className={`amp__modal ${isMobile() ? "" : "modal--aside"}`}
          clean
          side={isMobile() ? "center" : "right"}
          isOpened={isModalOpen}
          onClose={() => null}
        >
          <>
            <div className="modal-heading">
              {isVerified ? (
                isEdit ? (
                  "Edycja weryfikacji"
                ) : (
                  "Weryfikacja"
                )
              ) : (
                <>
                  {isAdditionalVisit
                    ? `${
                        isEdit ? "Edycja" : "Umawianie"
                      } montażu uzupełniającego`
                    : isMobile()
                    ? "Przypisz zlecenie"
                    : `${isEdit ? "Edycja" : "Umawianie"} pomiaru`}
                </>
              )}
            </div>
            {!isMobile() ? (
              <>
                <div className="amp__name">{orderDetails?.name}</div>
                <div className="modal__separator" />
              </>
            ) : (
              <>
                <div className="modal__information-header">Pracownik</div>
                <div className="modal__information-descript">{user.label}</div>
                {measurementDate()}
              </>
            )}
            <div className="modal__information-header">Adres klienta</div>
            <div className="modal__information-descript">
              {orderDetails?.address}
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={async (values, form) => handleOnSubmit(values, form)}
            >
              {({
                handleSubmit,
                errors,
                values,
                setFieldValue,
                setFieldTouched,
              }) => {
                return (
                  <form className="amp__form form" onSubmit={handleSubmit}>
                    {isAdditionalVisit ? (
                      <Field
                        component={AdditionalInstallation}
                        orderDetails={orderDetails}
                        errors={errors}
                        values={values}
                        setCheckList={setCheckList}
                        checkList={checkList}
                        setFieldTouched={setFieldTouched}
                      />
                    ) : (
                      <ArchivalDatesDetails
                        userRole={USER_ROLE.scheduleManager}
                        orderDetails={orderDetails}
                      />
                    )}
                    <Field
                      label={`${
                        isAdditionalVisit
                          ? "Uwagi do montażu uzupełniającego"
                          : "Uwagi dla pomiarowca/montera"
                      }`}
                      name={"comment"}
                      placeholder=""
                      component={Textarea}
                      onChange={(e: string) => setComment(e)}
                      errors={errors}
                    />
                    {!isMobile() && measurementDate()}
                    <div className="amp__hours">
                      <Field
                        label="Godzina rozpoczęcia"
                        name="start"
                        required
                        component={Select}
                        disableMobileKeyboard
                        menuPlacement={isMobile() ? "top" : "bottom"}
                        errors={errors}
                        options={startTimeOptions}
                        onChange={(e: any) =>
                          handleChangeStartTime(e, setFieldValue)
                        }
                      />
                      <div className="amp__hours-separate">-</div>
                      <Field
                        label="Godzina zakończenia"
                        name="end"
                        disabled={!selectedStartTime}
                        disableMobileKeyboard
                        menuPlacement={isMobile() ? "top" : "bottom"}
                        required
                        component={Select}
                        errors={errors}
                        options={endTimeOptions}
                        onChange={(e: any) =>
                          handleChangeEndTime(e, setFieldValue)
                        }
                      />
                    </div>

                    <div className="amp__buttons modal-buttons">
                      <Button
                        stroke
                        onClick={() => handleCancelMeasurement()}
                        label="Anuluj"
                      />
                      <Button type="submit" label="Potwierdź" />
                    </div>
                  </form>
                );
              }}
            </Formik>
          </>
        </Modal>
      )}
    </div>
  );
};

export default ArrangingMeasurementPanel;
