import { useState, useRef } from "react";
import {
  Modal,
  SVG,
  Switch,
  Input,
  Button,
  PhoneWithPrefix,
  Select,
  SingleDateRangePicker,
} from "components";
import * as Yup from "yup";
import { Field, FieldArray, Formik } from "formik";
import { SVG_TYPE, VALIDATION_MESSAGES } from "enums";
import { usePostOrderCreate, usePutOrderEdit } from "hooks/orders";
import { useGetMeasurementTypes } from "hooks/measurements";
import { usePhonePrefixes } from "hooks/utils";
import "./add-orders.scss";

interface AddOrdersType {
  isOpen: boolean;
  onClose: () => void;
  order: any;
}

const AddOrders = ({ isOpen, onClose, order }: AddOrdersType) => {
  const currentDate = new Date();
  const tommorow = currentDate.setDate(currentDate.getDate() + 1);
  const phoneNumberLengths: Record<any, number> = {
    "+48": 9, // Polska
    "+49": 11, // Niemcy
    "+43": 10, // Austria
    "+44": 10, // UK
    "+380": 9, // Ukraina
    "+420": 9, // Czechy
    "+421": 9, // Słowacja
    "+47": 8, // Norwegia
    "+354": 7, // Islandia
    "+33": 10, // Francja
  };
  const { data: prefixes } = usePhonePrefixes();
  const phonePrefixOptions = prefixes
    ? prefixes?.phonePrefixSerialize &&
      prefixes?.phonePrefixSerialize.length > 0 &&
      prefixes?.phonePrefixSerialize.map((item: any) => ({
        label: item.phonePrefix,
        value: item.value,
      }))
    : [];
  const enrichedPhonePrefixOptions =
    phonePrefixOptions &&
    phonePrefixOptions.map((option: any) => {
      const maxLength = phoneNumberLengths[option.label] || 9;
      return {
        ...option,
        maxLength,
      };
    });
  const [selectedOption, setSelectedOption] = useState(
    enrichedPhonePrefixOptions && enrichedPhonePrefixOptions[0]
  );

  const handlePrefixChange = (event: any) => {
    const selectedValue = event.label;
    const option =
      enrichedPhonePrefixOptions &&
      enrichedPhonePrefixOptions.find(
        (opt: any) => opt.label === selectedValue
      );
    setSelectedOption(option);
  };
  const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [isAdditionValidation, setIsAdditionValidation] = useState(false);
  const { data: measurementTypes } = useGetMeasurementTypes(isOpen);
  const { mutate: createOrder } = usePostOrderCreate({
    onSuccess: () => {
      onClose();
    },
  });
  const { mutate: editOrder } = usePutOrderEdit({
    onSuccess: () => {
      onClose();
    },
  });
  const isEdit = order.isEdit;

  const initialValues = {
    name: order?.name || "",
    clientName: order?.clientName || "",
    phonePrefixUuid: phonePrefixOptions ? phonePrefixOptions[0]?.value : +48,
    phoneNumber: order?.phoneNumber
      ? order.phoneNumber.split(" ")[1] || ""
      : "",
    email: order?.email || "",
    address: order?.address || "",
    type: "measurementsAndInstallation",
    files: [],
    measurements: [
      {
        typeUuid: "",
        counter: null,
        numberOfAssemblyTeams: null,
        plannedAssemblyTime: null,
        date: "",
      },
    ],
  };

  let validationSchema = {};

  validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN3)
      .matches(
        /^[^\s].*?[^\s]$/,
        VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
      ),
    clientName: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN3)
      .matches(
        /^[^\s].*?[^\s]$/,
        VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
      ),
    phoneNumber: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .test(
        "is-valid-length",
        "Nieprawidłowa długość numeru",
        function (value) {
          const { phonePrefixUuid } = this.parent;
          const selectedOption = enrichedPhonePrefixOptions.find(
            (opt: any) => opt.value === phonePrefixUuid
          );
          const maxLength = selectedOption?.maxLength || 9;
          return value?.length === maxLength;
        }
      ),
    type: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    email: Yup.string().email(VALIDATION_MESSAGES.EMAIL),
    address: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN3)
      .matches(
        /^[^\s].*?[^\s]$/,
        VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
      ),
  });
  if (isAdditionValidation && !isEdit) {
    validationSchema = Yup.object().shape({
      name: Yup.string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .min(3, VALIDATION_MESSAGES.MIN3)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
        ),
      clientName: Yup.string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .min(3, VALIDATION_MESSAGES.MIN3)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
        ),
      phoneNumber: Yup.string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .test(
          "is-valid-length",
          "Nieprawidłowa długość numeru",
          function (value) {
            const { phonePrefixUuid } = this.parent;
            const selectedOption = enrichedPhonePrefixOptions.find(
              (opt: any) => opt.value === phonePrefixUuid
            );
            const maxLength = selectedOption?.maxLength || 9;
            return value?.length === maxLength;
          }
        ),
      type: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
      email: Yup.string().email(VALIDATION_MESSAGES.EMAIL),
      address: Yup.string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .min(3, VALIDATION_MESSAGES.MIN3)
        .matches(
          /^[^\s].*?[^\s]$/,
          VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE
        ),
      measurements: Yup.array().of(
        Yup.object().shape({
          typeUuid: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          counter: Yup.number().required(VALIDATION_MESSAGES.REQUIRED),
          numberOfAssemblyTeams: Yup.number().required(
            VALIDATION_MESSAGES.REQUIRED
          ),
          plannedAssemblyTime: Yup.number().required(
            VALIDATION_MESSAGES.REQUIRED
          ),
          numberOfInstallationCrews: Yup.number().required(
            VALIDATION_MESSAGES.REQUIRED
          ),
          date: Yup.mixed()
            .required(VALIDATION_MESSAGES.REQUIRED)
            .test(
              "is-valid-date",
              VALIDATION_MESSAGES.REQUIRED,
              (value) =>
                typeof value === "string" ||
                (typeof value === "object" &&
                  value !== null &&
                  "dateStart" in value &&
                  "dateEnd" in value &&
                  typeof (value as any).dateStart === "string" &&
                  typeof (value as any).dateEnd === "string")
            ),
        })
      ),
    });
  }

  const handleOnSubmit = async (values: any) => {
    const { files, measurements, ...rest } = values;
    if (isEdit) {
      await editOrder({ ...rest, uuid: order.uuid });
    } else {
      await createOrder({
        ...rest,
        measurements:
          values.type === "measurementsAndInstallation" ? [] : measurements,
        files: values.type === "measurementsAndInstallation" ? [] : files,
      });
    }
  };

  const handleGlobalFileSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: Function,
    values: any
  ) => {
    const files = e.target.files;
    if (files) {
      const currentFiles = values.files || [];
      const newFiles = Array.from(files).filter((file) =>
        ["application/pdf", "image/jpeg", "image/jpg"].includes(file.type)
      );

      if (currentFiles.length + newFiles.length > 10) {
        alert("Możesz dodać maksymalnie 10 plików!");
      } else {
        const updatedFiles = [...currentFiles, ...newFiles];
        setFieldValue("files", updatedFiles);
      }
      e.target.value = "";
    }
  };

  const handleGlobalFileDelete = (
    fileIndex: number,
    setFieldValue: Function,
    values: any
  ) => {
    const updatedFiles = values.files.filter(
      (_: any, i: number) => i !== fileIndex
    );
    setFieldValue("files", updatedFiles);
  };

  return (
    <Modal
      side="right"
      className="order-preview"
      isOpened={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <div className="modal-heading add-orders">
        <div className="modal-heading__with-close">
          <div className="modal-heading">
            {isEdit ? "Edycja" : "Dodawanie"} zlecenia
          </div>
          <div
            className="modal-heading__close order-preview__heading"
            onClick={() => onClose()}
          >
            <span>Zamknij</span>
            <SVG type={SVG_TYPE.CLOSE} />
          </div>
        </div>
        <div className="modal__separator" />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={async (values) => handleOnSubmit(values)}
        >
          {({ handleSubmit, errors, values, setFieldValue }) => {
            return (
              <form className="add-user__form form" onSubmit={handleSubmit}>
                <Field
                  errors={errors}
                  label="Nazwa zlecenia"
                  name="name"
                  maxLength={50}
                  component={Input}
                />
                <Field
                  errors={errors}
                  label="Imię i nazwisko klienta"
                  maxLength={50}
                  name="clientName"
                  component={Input}
                />
                <PhoneWithPrefix>
                  <Field
                    label="Telefon"
                    name="phonePrefixUuid"
                    required
                    component={Select}
                    errors={errors}
                    options={enrichedPhonePrefixOptions}
                    onChange={handlePrefixChange}
                    size="small"
                  />
                  <Field
                    name="phoneNumber"
                    type="number"
                    errors={errors}
                    maxLength={selectedOption?.maxLength || 9}
                    component={Input}
                  />
                </PhoneWithPrefix>
                <Field
                  errors={errors}
                  label="Adres Email"
                  maxLength={50}
                  name="email"
                  component={Input}
                />
                <Field
                  errors={errors}
                  label="Adres klienta"
                  maxLength={50}
                  name="address"
                  component={Input}
                />
                {!isEdit && (
                  <Field
                    first="Pomiar + Montaż"
                    second="Montaż"
                    secondary
                    stringMode
                    label="Typ zlecenia"
                    name="type"
                    valueFirst={"measurementsAndInstallation"}
                    valueSecond={"installationOnly"}
                    component={Switch}
                    onChange={(e: any) => {
                      e.target.value === "installationOnly" &&
                        setIsAdditionValidation(true);
                      e.target.value === "measurementsAndInstallation" &&
                        setIsAdditionValidation(false);
                    }}
                  />
                )}
                {values.type === "installationOnly" && (
                  <>
                    <div className="modal__separator type-separator" />
                    <FieldArray
                      name="measurements"
                      render={(arrayHelpers) => (
                        <>
                          {values.measurements.map((_: any, index: number) => {
                            const notLast =
                              index !== values.measurements.length - 1;

                            const selectedTypeUuids = values.measurements
                              .map((measurement: any, i: number) =>
                                i !== index ? measurement.typeUuid : null
                              )
                              .filter(
                                (typeUuid: string | null) => typeUuid !== null
                              );

                            const availableOptions =
                              measurementTypes &&
                              measurementTypes.filter(
                                (option: any) =>
                                  !selectedTypeUuids.includes(option.value)
                              );
                            return (
                              <div
                                className="add-orders__measurements"
                                key={index}
                              >
                                <Field
                                  errors={errors}
                                  placeholder="Wybierz"
                                  label="Rodzaj montażu"
                                  name={`measurements.${index}.typeUuid`}
                                  component={Select}
                                  options={availableOptions}
                                />
                                <Field
                                  errors={errors}
                                  label="Ilość"
                                  name={`measurements.${index}.counter`}
                                  component={Input}
                                  maxLength={3}
                                  withDot
                                />
                                <Field
                                  errors={errors}
                                  label="Planowany czas montażu "
                                  name={`measurements.${index}.plannedAssemblyTime`}
                                  maxLength={6}
                                  type="number"
                                  rightPlaceholder="h"
                                  component={Input}
                                />
                                <Field
                                  errors={errors}
                                  label="Ilość osób potrzebnych do montażu"
                                  name={`measurements.${index}.numberOfAssemblyTeams`}
                                  type="number"
                                  component={Input}
                                  maxLength={3}
                                />
                                <Field
                                  errors={errors}
                                  label="Ilość ekip montażowych"
                                  name={`measurements.${index}.numberOfInstallationCrews`}
                                  type="number"
                                  component={Input}
                                  maxLength={3}
                                />
                                <Field
                                  errors={errors}
                                  label="Wstępny termin"
                                  name={`measurements.${index}.date`}
                                  component={SingleDateRangePicker}
                                  minDate={tommorow}
                                />
                                {values.measurements.length > 1 && (
                                  <div
                                    className="add-orders__measurements--remove"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <SVG type={SVG_TYPE.BIN} />
                                    Usuń ten montaż
                                  </div>
                                )}

                                {notLast && (
                                  <div
                                    className={`am-separator  am-separator--measurements-type`}
                                  />
                                )}
                              </div>
                            );
                          })}
                          <Button
                            className="add-orders__new-type"
                            size="medium"
                            stroke
                            label="Dodaj kolejny rodzaj montażu"
                            onClick={() => {
                              arrayHelpers.push({
                                typeUuid: "",
                                counter: null,
                                plannedAssemblyTime: null,
                                numberOfAssemblyTeams: null,
                                numberOfInstallationCrews: null,
                                files: [],
                              });
                            }}
                          />
                        </>
                      )}
                    />
                    <div className="add-orders__photos">
                      <div className="add-orders__photos-label">
                        Załącz pliki
                      </div>
                      {values.files && values.files.length < 3 && (
                        <Button
                          className="add-orders__photos-button"
                          stroke
                          svgType={SVG_TYPE.PLUS}
                          label="Dodaj"
                          size="small"
                          onClick={() => fileInputRefs.current[0]?.click()}
                        />
                      )}
                    </div>
                    <div className="add-orders__photos-list">
                      {values.files &&
                        values.files.length > 0 &&
                        values.files.map((file: any, fileIndex) => (
                          <div
                            key={fileIndex}
                            className="add-orders__photos-item"
                          >
                            <span>{file.name}</span>
                            <div
                              onClick={() =>
                                handleGlobalFileDelete(
                                  fileIndex,
                                  setFieldValue,
                                  values
                                )
                              }
                            >
                              <SVG type={SVG_TYPE.BIN} />
                            </div>
                          </div>
                        ))}
                    </div>
                    <input
                      name="files"
                      type="file"
                      ref={(el) => (fileInputRefs.current[0] = el)}
                      style={{ display: "none" }}
                      accept="application/pdf, image/jpeg, image/jpg"
                      onChange={(e) =>
                        handleGlobalFileSelect(e, setFieldValue, values)
                      }
                      multiple
                    />
                  </>
                )}

                <div className="modal-buttons add-user__actions">
                  <Button
                    secondary
                    stroke
                    onClick={() => onClose()}
                    label="Anuluj"
                  />
                  <Button secondary type="submit" label="Potwierdź" />
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddOrders;
